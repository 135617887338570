import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import React from 'react';

type AccordionProps = {
  children: React.ReactNode;
  header: React.ReactNode;
  initialState?: boolean;
};

const Accordion = ({
  children,
  header,
  initialState = false,
}: AccordionProps) => {
  const [isOpen, setIsOpen] = React.useState(initialState);
  return (
    <motion.div
      layout='position'

      className='w-full rounded-[20px] bg-grey-400 p-4'>
      <motion.div
        layout
        onClick={() => setIsOpen((prev) => !prev)}
        className='flex w-full cursor-pointer items-center justify-between px-2 text-left'>
        {header}
        <motion.div
          layout
          className=''
          animate={{ rotate: isOpen ? '90deg' : '0' }}>
          <FontAwesomeIcon icon={faChevronRight} className='text-lg' />
        </motion.div>
      </motion.div>

      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          layout>
          {children}
        </motion.div>
      )}
    </motion.div>
  );
};

export default Accordion;
