import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

export const resources = {
  fr: {
    translation: {
      Fnac: {
        Contest: {
          Stamp: "Concours",
          Create: "Envie de créer la carte Fnac+",
          FnacCard: "édition spéciale 70 ans ?",
          Participate: "Je participe",
          Infos: "Règlement du jeu concours",
        },
        Gallery: {
          Title: "Galerie",
          NoSubmission: "Il n'y a pas encore de créations.",
          NoSubmissionVisibleModerator:
            "Il n'y a pas encore de soumissions visibles...",
          NoSubmissionReportedModerator:
            "Il n'y a pas encore de soumissions à modérer...",
          Moderate: "Afficher les soumissions à modérer",
          Visible: "Afficher les soumissions visibles",
          Sort: "Trier",
          Likes: "Likes",
          Date: "Date",
          Error:
            "Quelque chose s'est mal passé lors de la récupération de la galerie du concours.",
        },
        MyContest: {
          Entry: "Ma carte FNAC+",
          Fnac70: "édition speciale 70 ans",
          Moderator: {
            Title: "Les soumissions au concours",
            TotalSubmissions: "Total :",
            VisibleSubmissions: "Visible :",
            ReportedSubmissions: "Signalée :",
            BlockedSubmissions: "Bloquée :",
          },
          Share: "Partager",
          MyDesign: "Ma carte",
          Download: "Télécharger l'image",
        },
        Panel: {
          Generate: "Imaginer",
          Submit: "Participer au concours",
          AlreadySubmitted: "Vous avez déjà soumis pour ce concours",
          NoInputText: "Aucun texte renseigné                  ",
          Consignes : {
            first: "Je dessine sur le tableau",
            second: "Je décris ici mon dessin",
            third: "Je choisis le style d'image généré par Beink Dream",
            fourth: "Je sélectionne mon dessin et je génère l'image", 
          }
        },
        ReportModal: {
          Title1: "Ce contenu est",
          Title2: "inacceptable ?",
          TeelUsMore: "Dites-nous en plus",
          SubmissionWarning:
            "Attention ! Vous êtes sur le point de signaler une soumission. Ce signalement masquera le contenu de la galerie de vote.",
          NoOveruseWarning:
            "Nous vous encourageons à utiliser cette fonction de manière responsable, car tout abus sera examiné attentivement.",
          ContributionThanks:
            "Merci de contribuer à une communauté respectueuse !",
          Placeholder: "...ou expliquez-nous avec vos propres mots",
          Cancel: "Annuler",
          Confirm: "Confirmer",
        },
        ReportOptions: {
          Select: "Sélectionnez votre raison",
          Nudity: "Nudité ou contenu sexuel explicite",
          Violence: "Violence ou brutalité",
          Hatred: "Discours de haine visuel",
          Terrorism: "Terrorisme ou extrémisme visuel",
          Fake: "Contenu trompeur ou fake news visuel",
          Shocking: "Contenu choquant ou dégoûtant",
          Privacy: "Violation de la vie privée",
          Spam: "Publicité non-autorisée ou spam",
          Other: "Autre",
        },
        SubmissionModal: {
          Title: "J'ajuste mon visuel",
          AdjustPosition:
            "Vous pouvez ajuster le positionnement de votre visuel sur la carte Fnac ci-dessous et retrouver cette création dans votre galerie.",
          Cancel: "Annuler",
          Confirm: "Valider ma participation",
        },
      },
      Header: {
        CreatorCTA: "Je participe",
      },
      Beink: {
        Tabs: {
          generate: "Générer",
          results: "Résultats",
          help: "Aide",
        },
        Modes: {
          imagine: "Imagination",
          inpainting: "Édition Magique",
        },
        Generate: {
          graphiceStyle: "Style graphique",
          prompt: "Décrivez votre dessin",
          promptPlaceholder: "Que créez-vous ?",
          btnEnable: "Générer",
          btnDisable: "Sélectionnez un dessin",
        },
        Inpaint: {
          prompt: "Décrivez le visuel souhaité dans la zone sélectionnée",
          promptPlaceholder: "Exemple : herbe rouge",
          btnDisable:
            "Sélectionnez une image, dessinez une zone avec l'outil crayon et décrivez le visuel souhaité",
          btnWarning: "Attention :",
          btnWarningMask:
            "la zone sélectionnée en dehors de l'image sera ignorée",
          btnWarningAnd: "et",
          btnWarningQuality:
            "l'image est de petite taille, une perte de qualité est possible durant l'édition",
          btnEnable: "Appliquer",
        },
        Result: "Cliquez pour importer l'image sur le tableau",
        Help: {
          tutoStep1: "Dessine ton idée",
          tutoStep2: "Décris la",
          tutoStep3: "Génère des images",
          tutoStep4: "Répéte et améliore",
          content: `<p>Ici, il n'y a aucune limite à votre imagination ni aucune barrière à votre talent. Exprimez vos idées avec une clarté saisissante, visualisez et donnez vie à vos concepts d'un seul geste.</p>
          <p>Pensez plus vite et plus loin, libérez votre créativité, seul ou à plusieurs. Plongez dans un monde où vos rêves prennent forme avec une vitesse étonnante et une précision inégalée.</p>`,
          contactBtn: "Contacter l'équipe Beink Dream",
          btnDemo: "Voir le tuto",
          btnStart: "Commencez maintenant",
          rulesTitle: "Il est interdit d'utiliser Beink Creator pour créer :",
          rulesList: `<0>du contenu violent ou gore</0>
            <0>du contenu pour adultes</0>
            <0>du contenu offensant ou abusif</0>`,
          accetpTerms: `Pour commencer à générer, <1>acceptez les CGU</1>.`,
          termsUrl:
            'https://beink-dream.notion.site/Beink-Dream-Conditions-G-n-rales-d-Utilisation-et-de-Vente-726fb65df2e348c7b24cb3fbd6157b27',
          logout: "Déconnexion",
        },
        Loading: {
          title: "Chargement",
          waiting:
            "Veuillez patienter, Beink Dream est en train de créer vos images... 🦩🎨",
        },
        Attempts_one: "<strong>{{count}}</strong> essai restant",
        Attempts_other: "<strong>{{count}}</strong> essais restants",
        ReviewLink: "Partagez votre avis avec l'équipe Beink Dream !",
        EndTrial: {
          title: "<0>Merci</0> d'avoir choisi Beink Dream 🚀",
          content: `<p>Vous avez atteint la limite des essais gratuits pour aujourd'hui.</p>
          <p>Reconnectez vous demain pour de nouvelles générations !</p>
          <p>De nouvelles fonctionnalités seront bientôt disponibles.</p>`,
          content_vip: `<p>Vous avez atteint la limite des essais gratuits pour ce mois.</p>
          <p>Reconnectez vous le mois prochain pour de nouvelles générations !</p>
          <p>De nouvelles fonctionnalités seront bientôt disponibles.</p>`,
          link: "Souscrivez pour plus de générations",
        },
        Error: {
          default: `Un problème est survenu, veuillez réessayer plus tard. Si le problème persiste, n'hésitez pas à <a href="mailto:support@beink-dream.fr">contacter notre équipe de support</a>.`,
          noAttemptLeft: `Il semble que vous ayez épuisé tous vos essais. Pour continuer d'utiliser Beink Dream, veuillez nous <a href="mailto:support@beink-dream.fr">contacter</a> pour obtenir une assistance adaptée à vos besoins.`,
          noAttemptLeftButton: "Plus de générations disponibles",
          unsafe: `Oups ! Il semble que votre contenu ne respecte pas nos directives. Pas d'inquiétude, consultez notre <a href="https://beink-dream.notion.site/Beink-Dream-Guide-des-Contenus-Acceptables-b1d03bfbc2ea4141b93c2dc0b30bc794?pvs=4" target="_blank">Guide des Contenus Acceptables</a> pour des conseils et astuces.`,
          wrongKey: `Hmm, il semble y avoir un problème avec votre authentification. Veuillez actualiser la page ou <a href="mailto:support@beink-dream.fr">contacter notre support</a> pour obtenir de l'aide.`,
        },
      },
      DesignStyle: {
        Industrial: "Design industriel",
        Line: "Design Ligne",
        Davinci: "Design style Davinci",
        HandSketch: "Croquis à la main",
        FashionDrawing: "Dessins de mode",
        Scientific: "Dessin scientifique",
        Photorealistic: "Design réaliste",
      },
      KeyForm: {
        title: "Essayez <1>Beink Creator</1> maintenant !",
        askContent: "Si vous n'avez pas de clé, <1>demandez-en une ici</1>",
        inputPlaceholder: "Votre clé d'essai",
        btnActivate: "Activer",
        errorValid: "Votre clé n'est pas valide",
        errorServer: "Nous n'avons pas pu vérifier cette clé",
      },
      Whiteboard: {
        ShortcutTitle: "Raccourcis clavier",
        DragFiles: "Importer une ou plusieurs image(s)",
        NoImageImportFnac: "L'import d'image n'est pas autorisé pour le concours",
        Tools: {
          pen: "Outil Pinceau",
          eraser: "Outil Gomme",
          select: "Outil Sélection",
          crop: "Outil Recadrage",
          hand: "Outil Déplacement",
          text: "Outil Texte",
          eyedropper: "Outil Pipette",
          colorPicker: "Sélecteur de couleur",
          penSize: "Epaisseur du pinceau",
          deleteSelected: "Supprimer la sélection",
          deleteAll: "Effacer tout",
          resetZoom: "Réinitialiser le zoom",
          undo: "Annuler",
          redo: "Rétablir",
          uploadImage: "Charger une image",
          downloadImage: "Télécharger la selection",
          bringToFront: "Mettre au premier plan",
          sendToBack: "Envoyer au dernier plan",
        },
      },
      Home: {
        LaunchApp: "Lancer l'app",
        Welcome: "Bienvenue",
        Token: "Jeton",
        CopyToast: "Jeton copié dans le presse-papier !",
        Nav: {
          Home: "Accueil",
          Account: "Compte",
          TeamManagement: "Gérer",
          Subscription: "Abonnement",
          Billing: "Facturation",
          Language: "Langue",
          SignOut: "Deconnexion",
          BackHome: "Retour",
          Cancel: "Annuler",
        },
        NavTitle: {
          Profile: "Profil",
          Settings: "Paramètres",
          Team: "Mon Équipe",
          Contest: "Jeu Concours",
          MyCreations: "Mes Créations",
        },
      },
      Subscription: {
        title: "Abonnez-vous à Beink Dream !",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        Payment: {
          Success: {
            title: "Merci de votre inscription",
            content:
              "Bienvenue dans la communauté Beink Dream, vous pouvez dès maintenant commencer à créer et laisser parler votre imagination sans limites !",
            link: "Commencez à créer",
          },
          Fail: {
            title: "Une erreur s'est produite lors de l'abonnement",
            content: "Veuillez réessayer ou contacter l'équipe Beink Dream.",
            link: "Contactez nous",
          },
        },
        Offers: {
          btn: "S'abonner",
          monthly: "/mois",
          free: {
            desc: "Pour découvrir",
            details: {
              line1: "5 générations par jour",
            },
          },
          starter: {
            desc: "Créez avec Beink Dream",
            details: {
              line1: "500 générations par mois",
              line2: "Powerful Beink AI",
              line3: "Support available",
            },
          },
        },
      },
      Team: {
        Title: "Membres",
        Description: "Gérer votre équipe en toute simplicité !",
        Stats: {
          Title: "Statistiques",
          Count: "Nombre de Membres",
        },
        Metadata: {
          User: "Nom",
          Role: "Role",
        },
        Role: {
          Manager: "Manageur",
          User: "Utilisateur",
        },
      },
      Generations: {
        Title: "Mes créations",
        NoGenerations: "Il n'y a pas encore de générations.",
        BtnStart: "Commencez à créer maintenant !",
        LoadMore: "Charger plus",
        Error:
          "Quelque chose s'est mal passé lors de la récupération de vos générations...",
      },
      NotFound: "Page non trouvée",
    },
  },
  en: {
    translation: {
      Fnac: {
        Contest: {
          Stamp: "Contest",
          Create: "Design the next",
          FnacCard: "Fnac+ Card",
          Participate: "I'm participating",
          Infos: "Contest Rules",
        },
        Gallery: {
          Title: "FNAC 70th ANNIVERSARY gallery",
          NoSubmission: "You are the first...",
          NoSubmissionVisibleModerator: "No submission yet...",
          NoSubmissionReportedModerator: "No submission to moderate yet...",
          Moderate: "Display submissions to moderate",
          Visible: "Display visible submissions",
          Sort: "Sort",
          Likes: "Likes",
          Date: "Date",
          Error: "Something went wrong while retrieving the contest gallery.",
        },
        MyContest: {
          Entry: "My submission for the contest",
          Fnac70: "70th anniversary contest",
          Moderator: {
            Title: "The context submissions",
            TotalSubmissions: "Total:",
            VisibleSubmissions: "Visible:",
            ReportedSubmissions: "Reported:",
            BlockedSubmissions: "Blocked:",
          },
          Share: "Share",
          MyDesign: "My design",
          Download: "Download my image",
        },
        Panel: {
          Generate: "Imagine",
          Submit: "Submit to the contest",
          AlreadySubmitted: "Already submitted",
          NoInputText: "No text provided",
          Consignes : {
            first: "Draw on the board",
            second: "Describe your drawing here",
            third: "Choose the graphic style of the image generated by Beink Dream",
            fourth: "Select your drawing and generate the image", 
          }

        },
        ReportModal: {
          Title1: "This content is",
          Title2: "unacceptable ?",
          TeelUsMore: "Tell us more",
          SubmissionWarning:
            "Warning! You are about to report a submission. This report will hide the content from the voting gallery.",
          NoOveruseWarning:
            "We encourage you to use this feature responsibly, as any misuse will be carefully reviewed",
          ContributionThanks:
            "Thank you for contributing to a respectful community!",
          Placeholder: "...or explain to us in your own words",
          Cancel: "Cancel",
          Confirm: "Confirm",
        },
        ReportOptions: {
          Select: "Select your reason",
          Nudity: "Nudity or explicit sexual content",
          Violence: "Violence or brutality",
          Hatred: "Visual hate speech",
          Terrorism: "Visual terrorism or extremism",
          Fake: "Misleading content or visual fake news",
          Shocking: "Shocking or disgusting content",
          Privacy: "Privacy violation",
          Spam: "Unauthorized advertisement or spam",
          Other: "Other",
        },
        SubmissionModal: {
          Title: "I adjust my visual",
          AdjustPosition:
            "You can adjust the positioning of your visual on the Fnac map below and find this creation in your gallery.",
          Cancel: "Cancel",
          Confirm: "Validate my participation",
        },
      },
      Header: {
        CreatorCTA: "Start creating !",
      },
      Beink: {
        Tabs: {
          generate: "Generate",
          results: "Results",
          help: "Help",
        },
        Modes: {
          imagine: "Imagine",
          inpainting: "Magic Edit",
        },
        Generate: {
          graphiceStyle: "Graphic style",
          prompt: "Describe your design",
          promptPlaceholder: "What are you creating?",
          btnEnable: "Generate",
          btnDisable: "Select an element with a prompt",
        },
        Inpaint: {
          prompt: "Describe the desired visual in the selected area",
          promptPlaceholder: "Example: red grass",
          btnDisable:
            "Select an image, draw an area with the pencil tool and describe the desired visual",
          btnWarning: "Warning:",
          btnWarningMask: "any selected area outside the image will be ignored",
          btnWarningAnd: ", and",
          btnWarningQuality:
            "The image is small. Quality loss may occur during editing",
          btnEnable: "Apply",
        },
        Result: "Click to import image on the board",
        Help: {
          tutoStep1: "Draw your idea",
          tutoStep2: "Describe it",
          tutoStep3: "Generate images",
          tutoStep4: "Repeat and improve",
          content: `<p>Here, there are no limits to your imagination and no barriers to
            your talent. Express your ideas with dazzling clarity, visualize and
            bring your concepts to life with a single gesture.</p>
          <p>Think faster and farther, unleash your creativity, whether alone or
            together. Dive into a world where your dreams take shape with
            astonishing speed and unparalleled precision.</p>`,
          contactBtn: "Contact the Beink Dream team",
          btnDemo: "Watch the tutorial",
          btnStart: "Start now",
          rulesTitle: "It is prohibited to use Beink Creator to make:",
          rulesList: `<0>gore or violent content</0>
            <0>adult content</0>
            <0>offensive or abusive content</0>`,
          accetpTerms: `To start generating, accept <1>the terms</1>.`,
          termsUrl:
            'https://beink-dream.notion.site/Beink-Dream-Terms-Conditions-13f3a9a42edb4dcba7ac87e7f53f55ab',
          logout: "Logout",
        },
        Loading: {
          title: "Loading",
          waiting: `Hang tight! Beink Dream is busy creating your images... 🦩🎨`,
        },
        Attempts_one: "<strong>{{count}}</strong> attempt left",
        Attempts_other: "<strong>{{count}}</strong> attempts left",
        ReviewLink: "Share your thoughts with us!",
        EndTrial: {
          title: "<0>Thank you</0> for choosing Beink Dream 🚀",
          content: `<p>You've reached the free trial limit for today.</p>
          <p>Come back tomorrow for new generations!</p>
          <p>New features will be available soon.</p>`,
          content_vip: `<p>You have reached the free trial limit for this month.</p>
          <p>Come back next month for new generations!</p>
          <p>New features will be available soon.</p>`,
          link: "Subscribe for more generations",
        },
        Error: {
          default: `Something went wrong, please try again later. If the issue persists, feel free to <a href="mailto:support@beink-dream.fr">contact our support team</a>.`,
          noAttemptLeft: `It seems that you have used up all your attempts. To continue using Beink Dream, please <a href="mailto:support@beink-dream.fr">contact us</a> to get assistance tailored to your needs.`,
          noAttemptLeftButton: "No more generations available",
          unsafe: `Oops! It looks like your content doesn't meet our guidelines. No worries, check out our <a href="https://beink-dream.notion.site/Beink-Dream-Content-Guidelines-29536002751a4a529dae9fa82292e4bf?pvs=4" target="_blank">Content Guidelines</a> for tips and advice.`,
          wrongKey:
            'Hmm, there seems to be an issue with your authentication. Please refresh the page or <a href="mailto:support@beink-dream.fr">contact our support</a> for assistance.',
        },
      },
      DesignStyle: {
        Industrial: "Industrial design",
        Line: "Line design",
        Davinci: "Davinci design",
        HandSketch: "Hand sketch design",
        FashionDrawing: "Fashion drawing design",
        Scientific: "Scientific drawing",
        Photorealistic: "Realistic design",
      },
      KeyForm: {
        title: "Try <1>Beink Creator</1> now!",
        askContent: "If you don't have a trial key, <1>request one here</1>",
        inputPlaceholder: "Your trial key",
        btnActivate: "Activate",
        errorValid: "Your key isn't valid",
        errorServer: "We could not verify this key",
      },
      Whiteboard: {
        ShortcutTitle: "Keyboard shortcuts",
        DragFiles: "Drop one or more images to import onto the whiteboard",
        NoImageImportFnac: "Image import is not allowed for the competition",
        Tools: {
          pen: "Pen tool",
          eraser: "Eraser tool",
          select: "Selector tool",
          crop: "Cropping tool",
          hand: "Move/Hand tool",
          text: "Text tool",
          eyedropper: "Eyedropper tool",
          colorPicker: "Color Picker",
          penSize: "Pen thickness",
          deleteSelected: "Remove selection",
          deleteAll: "Remove all",
          resetZoom: "Reset zoom",
          undo: "Undo",
          redo: "Redo",
          uploadImage: "Upload an image",
          downloadImage: "Download as image",
          bringToFront: "Bring selection to front",
          sendToBack: "Send selection to back",
        },
      },
      Home: {
        LaunchApp: "Launch app",
        Welcome: "Welcome",
        Token: "Token",
        CopyToast: "Token copied to clipboard!",
        Nav: {
          Home: "Home",
          Account: "Account",
          TeamManagement: "Manage",
          Subscription: "Subscription",
          Billing: "Billing",
          Language: "Language",
          SignOut: "Sign out",
          BackHome: "Back",
          Cancel: "Cancel",
        },
        NavTitle: {
          Profile: "Profile",
          Settings: "Settings",
          Team: "My Team",
          Contest: "Contest Gallery",
          MyCreations: "My Creations",
        },
      },
      Subscription: {
        title: "Subscribe to Beink Dream!",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        Payment: {
          Success: {
            title: "Thank you for subscribing",
            content:
              "Welcome to the Beink Dream community, you can now start creating and letting your imagination speak without limits!",
            link: "Start to create now",
          },
          Fail: {
            title: "An error occured while subscribing",
            content: "Please retry or contact the Beink Dream Team.",
            link: "Contact us",
          },
        },
        Offers: {
          btn: "Subscribe",
          monthly: "/month",
          free: {
            desc: "Discover Beink Dream",
            details: {
              line1: "5 generations per day",
            },
          },
          starter: {
            desc: "Start creating with Beink Dream",
            details: {
              line1: "500 generations per month",
              line2: "Powerful Beink AI",
              line3: "Support available",
            },
          },
        },
      },
      Team: {
        Title: "Members",
        Description: "Manage your team with ease!",
        Stats: {
          Title: "Statistiques",
          Count: "Member Count",
        },
        Metadata: {
          User: "Name",
          Role: "Role",
        },
        Role: {
          Manager: "Manager",
          User: "User",
        },
      },
      Generations: {
        Title: "My creations",
        NoGenerations: "There are no generations yet.",
        BtnStart: "Start creating now!",
        LoadMore: "Load more",
        Error: "Something went wrong while fetching your generations...",
      },
      NotFound: "Page not found",
    },
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "fr", // Use French as fallback
    supportedLngs: ["fr", "en"],
    interpolation: { escapeValue: false },
  });

export default i18n;
