import { memo } from "react";
import { Trans } from "react-i18next";
import { $generationsLeft } from "../../../../store/generations-left";
import { useStore } from "@nanostores/react";
import { motion } from "framer-motion";

const AttemptsCounter = memo(function ({

    className,
}: {

    className?: string;
}) {
    const attemptsCount = useStore($generationsLeft);


    const clampAttemptsCount = Math.max(0, attemptsCount);

    return (
        <motion.div layout className={className}>
            <Trans
                i18nKey="Beink.Attempts"
                count={attemptsCount}
                values={{ count: clampAttemptsCount }}
            />
        </motion.div>
    );
});

export default AttemptsCounter;