/**
 * Formats a number to a string representation with 'k' for thousands.
 *
 * @param num - The number to format.
 * @returns The formatted string with 'k' if the number is 1000 or greater, otherwise the number as a string.
 */
export const formatNumberToK = (num: number): string => {
    if (num >= 1000) {
        return (num / 1000).toFixed(1) + 'k';
    }
    return num.toString();
};

/**
 * Formats a given date into a string in the format "DD/MM/YYYY".
 *
 * @param date - The date to format. Can be a string, number, or Date object.
 * @returns The formatted date string.
 */
export const formatDate = (date: string | number | Date): string => {
    const dateData = new Date(date);
    const dateString = `${dateData.getDate()}/${dateData.getMonth() + 1}/${dateData.getFullYear()}`;
    return dateString;
};

/**
 * Converts a given date string to a human-readable "time ago" format.
 * 
 * @param createdAt - The date string representing the creation time.
 * @returns A string representing the time elapsed since the given date in hours or days.
 * 
 * @example
 * ```typescript
 * const formattedTime = dateToXTimeAgoFormat("2023-10-01T12:00:00Z");
 * console.log(formattedTime); // "Il y a 2 jour(s)" if today is 2023-10-03
 * ```
 */
export const dateToXTimeAgoFormat = (createdAt: string): string => {
    const date = new Date(createdAt);

    // Calculate the time since the generation was created
    const timeSinceCreation = new Date().getTime() - date.getTime();
    const daysSinceCreation = timeSinceCreation / (1000 * 3600 * 24);

    // Create a string that represents the time (hours or days since the generation was created)
    if (daysSinceCreation < 1) {
        return `Il y a ${Math.floor(daysSinceCreation * 24)} h`;
    } else {
        return `Il y a ${Math.floor(daysSinceCreation)} jour(s)`;
    }
};