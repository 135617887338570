import { useKeycloak } from '@react-keycloak/web';
import { useState, useEffect } from 'react';

type UseImageWithAuth = (path: string) => { imgUrl: string; loading: boolean };

const useImageWithAuth: UseImageWithAuth = (path) => {
  const { keycloak } = useKeycloak();
  const [imgUrl, setImgUrl] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const apiUrl = import.meta.env.VITE_API_URL;

  useEffect(() => {
    if (!keycloak) return;

    if (!path.startsWith('/')) path = '/' + path;

    setLoading(true);

    fetch(apiUrl + path, {
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        setImgUrl(URL.createObjectURL(blob));
      })
      .finally(async () => {
        //Artificial wait

        setLoading(false);
      });

    return () => {
      URL.revokeObjectURL(imgUrl);
    };
  }, [apiUrl, path]);

  return { imgUrl, loading };
};

export default useImageWithAuth;
