import useImageWithAuth from '../../../../hooks/useImageWithAuth';
import { ImgHTMLAttributes } from 'react';

import { Loader2 } from 'lucide-react';
import { motion } from 'framer-motion';

type ResultsGenerationImageProps = ImgHTMLAttributes<HTMLImageElement> & {
  imagePath: string;
  importImage: (url: string) => void;
};

const ResultsGenerationImage = ({
  imagePath,
  importImage,
  ...rest
}: ResultsGenerationImageProps) => {
  const { imgUrl, loading } = useImageWithAuth(imagePath);

  return loading ? (
    <motion.div
      layout
      className='flex h-full flex-col items-center justify-center rounded-[18px] bg-fnac-dark-gray p-5 text-[14px]'>
      <Loader2 className='mx-auto animate-spin text-primary' size={30} />
    </motion.div>
  ) : (
    <img
      {...rest}
      src={imgUrl}
      onClick={() => {
        importImage(imgUrl);
      }}
    />
  );
};

export default ResultsGenerationImage;
