import { useKeycloak } from "@react-keycloak/web";
import i18next, { changeLanguage } from "i18next";
import {

  Languages,
  LogOut,

  UserRound,
  Users,
} from "lucide-react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import {
  fnacEnabled,

  teamManagementEnabled,
} from "../../../../helpers/FeatureFlags";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight, faMagicWandSparkles, faPenNib } from "@fortawesome/free-solid-svg-icons";


export const NavBar = () => {
  const { keycloak } = useKeycloak();
  const { t } = useTranslation();

  const userRoles: string[] = keycloak.realmAccess?.roles || [];
  const isTeamManager = userRoles.includes("beink-users-team-manager");

  const isTeamDisplayed = teamManagementEnabled && isTeamManager;

  const toggleLang = () => {
    changeLanguage(i18next.language === "fr" ? "en" : "fr");
  };

  return (
    <aside className="flex w-min shrink-0 flex-col border-t border-fnac-gray bg-fnac-dark-gray py-0 md:min-w-60">
      <nav className="flex grow flex-col text-base">

        <NavLink
          to="/gallery"
          className={({ isActive }) =>

            `flex justify-start px-3 py-5 space-x-2 items-center text-fnac-mustard font-ccsign ${isActive ? "bg-fnac-gray" : "hover:bg-grey-400"} `

          }
        >
          {({ isActive }) => (
            <>
              <FontAwesomeIcon icon={faMagicWandSparkles} width={22} />
              <span className="hidden md:block">{t("Home.NavTitle.Contest")}</span>

              {isActive &&
                <FontAwesomeIcon icon={faCaretRight} width={22} className="hidden md:block" />
              }
            </>
          )}
        </NavLink>

        <NavLink
          to="/"
          className={({ isActive }) =>
            isActive
              ? "px-3 py-5 bg-fnac-gray w-full flex items-center gap-3"
              : "px-3 py-5 w-full flex items-center gap-3 hover:bg-grey-400/50"
          }
        >
          {({ isActive }) => (
            <>
              <FontAwesomeIcon icon={faPenNib} width={22} />
              <span className="hidden md:block">
                {t("Home.NavTitle.MyCreations")}
              </span>

              {isActive &&
                <FontAwesomeIcon icon={faCaretRight} width={22} className="hidden md:block" />
              }
            </>

          )}
        </NavLink>

        {!fnacEnabled && (
          <div className="hidden p-6 pb-2 text-sm uppercase text-primary md:block">
            {t("Home.NavTitle.Profile")}
          </div>
        )}

        {!fnacEnabled && (
          <>
            <div className="hidden p-6 pb-2 text-sm uppercase text-primary md:block">
              {t("Home.NavTitle.Settings")}
            </div>
            <NavLink
              to={keycloak.createAccountUrl()}
              className={({ isActive }) =>
                isActive
                  ? "md:p-6 p-4 bg-fnac-gray w-full flex items-center gap-3"
                  : "md:p-6 p-4 w-full flex items-center gap-3 hover:bg-grey-400/50"
              }
            >
              <UserRound width={22} />
              <span className="hidden md:block">Mon Compte</span>
            </NavLink>
          </>
        )}
        {!fnacEnabled && isTeamDisplayed && (
          <>
            <div className="hidden p-6 pb-2 text-sm uppercase text-primary md:block">
              {t("Home.NavTitle.Team")}
            </div>
            <NavLink
              to="/team"
              className={({ isActive }) =>
                isActive
                  ? "md:p-6 p-4 bg-fnac-gray w-full flex items-center gap-3"
                  : "md:p-6 p-4 w-full flex items-center gap-3 hover:bg-grey-400/50"
              }
            >
              <Users width={22} />
              <span className="hidden md:block">
                {t("Home.Nav.TeamManagement")}
              </span>
            </NavLink>
          </>
        )}
      </nav>
      <div className="flex grow-0 flex-col text-base">
        <button
          className="flex w-full items-center gap-3 px-3 py-3 hover:bg-grey-400/50 md:px-6"
          onClick={toggleLang}
        >
          <Languages width={20} />
          <span className="hidden md:block">{t("Home.Nav.Language")} :</span>

          <div className="hidden gap-3 font-bold uppercase md:flex">
            {[...i18next.languages].map((lang) => {
              const classes = lang === i18next.language ? "text-primary" : "text-white";
              return (
                <div className={classes} key={lang}>
                  {lang}
                </div>
              );
            })}
          </div>
        </button>

        <button
          className="flex w-full items-center gap-3 px-3 py-3 hover:bg-grey-400/50 md:px-6"
          onClick={() => {
            if (fnacEnabled) {
              keycloak.logout({
                redirectUri: import.meta.env.VITE_FNAC_SSO_LOGOUT_URI, // Fnac-specific logout URI
              });
            } else {
              keycloak.logout(); // Default logout behavior
            }
          }}
        >
          <LogOut width={20} />
          <span className="hidden md:block">{t("Home.Nav.SignOut")}</span>
        </button>
      </div>
    </aside>
  );
};
