import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";


export const FreshChallengerHeaderCard: React.FC = () => {
  const { t } = useTranslation();

  return (

    <NavLink to="/creator">

      <div onClick={() => { }} className="px-4 py-6 flex flex-col sm:items-start md:items-start md:w-full xl:max-w-screen-2xl">
        <div className="grid xs:grid-cols-1 sm:grid-cols-2 p-4 lg:p-8 bg-fnac-radial-gradient border border-hidden rounded-lg justify-items-center gap-4 place-items-center sm:w-full md:max-w-4xl md:px-8">
          <div className="flex flex-col w-full items-center sm:p-6">

            <h2 className="text-base uppercase w-44 lg:w-64 lg:text-2xl">
              <span className="font-jakarta font-extrabold">
                {t("Fnac.Contest.Create")}
              </span>{" "}<br />
              <span className="font-ccsign font-bold text-fnac-mustard xs:text-sm lg:text-xl">
                {t("Fnac.Contest.FnacCard")}
              </span>
            </h2>
          </div>
          <div className="w-full h-36 lg:h-44 lg:-my-6 bg-no-repeat bg-center bg-contain bg-[url('/v2/fnac_card_empty.svg')]"></div>
        </div>
      </div>
    </NavLink>

  );
};
