import {
  HTMLMotionProps,
  motion,
  useMotionTemplate,
  useTime,
  useTransform,
} from 'framer-motion';


type GradientButtonProps = HTMLMotionProps<'button'> & {

  children: string;
};

const GradientButton = ({
  children,
  className,
  ...rest
}: GradientButtonProps) => {
  const time = useTime();
  //motion value cyclic time
  let phase = useTransform(() => (Math.sin(time.get() / 150)));
  //clamp motion value between 10 and 90
  phase = useTransform(phase, [-1, 1], [20, 80]);

  const gradient = useMotionTemplate`linear-gradient(90deg, #0EC88A 0%, #0BB379 ${phase}%, #A14AFF 100%)`;

  //Slit the string into an array of strings
  const splitString = children.split('');

  //variants on opacity for the characters
  const items = {
    hidden: { opacity: 0.5 },
    visible: { opacity: 1 },
  };

  return (
    <motion.button
      layout
      //gradient style
      style={{ background: gradient }}
      {...rest}
      className={`cursor-pointer rounded-xl ${className} mt-2 flex w-full items-center justify-center gap-2 rounded-2xl !p-4 font-jakarta !text-base !font-extrabold !text-white`}>
      <motion.span
        initial='hidden'
        animate='visible'
        transition={{ staggerChildren: 0.01 }}>
        {splitString.map((char, index) => (
          <motion.span
            key={index}
            variants={items}
            transition={{
              repeat: Infinity,
              repeatType: 'reverse',
              duration: 0.2,
            }}>
            {char}
          </motion.span>
        ))}
      </motion.span>
    </motion.button>
  );
};

export default GradientButton;
