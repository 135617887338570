import { useKeycloak } from '@react-keycloak/web';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { $generationsLeft } from '../store/generations-left';
import type { DataAiFromApi, Proposals } from '../types';
import useAppContext from './useAppContext';
import { dataURItoBlob } from '../helpers/ImageManipulation';
import { $generations } from '../store/generations';

const useInpaint = () => {
  const { t } = useTranslation();
  const { generationsLeft } = useAppContext();
  const { keycloak } = useKeycloak();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [proposals, setProposals] = useState<Proposals>([]);

  const inpaintImage = async ({
    prompt,
    scribble,
    mask,
  }: {
    prompt: string;
    scribble: string;
    mask: string;
  }) => {
    if (!scribble.startsWith('data:image/png;base64,')) {
      throw new Error('Invalid scribble format');
    }

    if (!mask.startsWith('data:image/png;base64,')) {
      throw new Error('Invalid mask format');
    }

    if (!keycloak.token) {
      return setError(t('Beink.Error.wrongKey'));
    }

    if (generationsLeft < 1) {
      return setError(t('Beink.Error.noAttemptLeft'));
    }

    setIsLoading(true);
    setError('');

    const formData = new FormData();
    formData.append('prompt', prompt);
    formData.append('source', 'Webapp');
    formData.append('scribble', dataURItoBlob(scribble), 'scribble.png');
    formData.append('mask', dataURItoBlob(mask), 'mask.png');

    const url = new URL('/ai/inpaint-sync', import.meta.env.VITE_API_URL);

    let success = false;

    await fetch(url, {
      body: formData,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        authorization: `Bearer ${keycloak.token}`,
      },
    })
      .then((res) => res.json())
      .then((data: DataAiFromApi) => {
        if (data.status === 'completed') {
          success = true;
          console.log(data);

          setProposals(data.urls ?? data.upscaled_urls ?? []);
          $generationsLeft.set(data.generationsLeft);
          $generations.set([
            {
              createdAt: data.createdAt,
              urls: data.urls ?? [],
              prompt: data.prompt,
            },
            ...$generations.get(),
          ]);
        } else {
          console.error(data);
          setError(t('Beink.Error.default'));
          setProposals([]);
        }
      })
      .catch((err) => {
        console.error(err);
        setError(t('Beink.Error.default'));
      })
      .finally(() => {
        setIsLoading(false);
      });

    return success;
  };

  return {
    isLoading,
    error,
    proposals,
    inpaintImage,
  };
};

export default useInpaint;
