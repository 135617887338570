import { Eye, EyeOff } from 'lucide-react';
import { useState } from 'react';
import { motion } from 'framer-motion';

import ResultsGenerationImage from './ResultsGenerationImage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-regular-svg-icons';

type ExpandableCardProps = {
  initialExpand: boolean;
  genId: string;
  createdAt: string;
  images: string[];
  index: number;

  importImage: (img: string) => void;
};

export const ExpandableCard: React.FC<ExpandableCardProps> = ({
  initialExpand = false,
  images,
  createdAt,

  importImage,
}: ExpandableCardProps) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(initialExpand);

  const date = new Date(createdAt);

  //Calculate the time since the generation was created
  const timeSinceCreation = new Date().getTime() - date.getTime();
  const daysSinceCreation = timeSinceCreation / (1000 * 3600 * 24);

  //Create a string that represents the time (hours or days since the generation was created)
  let timeSinceCreationString = '';

  if (daysSinceCreation < 1) {
    timeSinceCreationString = `Il y a ${Math.floor(daysSinceCreation * 24)} h`;
  } else {
    timeSinceCreationString = `Il y a ${Math.floor(daysSinceCreation)} jour(s)`;
  }

  return (
    <motion.div
      layout='position'
      className={`flex w-full flex-col rounded-2xl bg-white p-4 ${isExpanded ? 'bg-opacity-15' : 'bg-opacity-5'}`}>
      <div className='flex items-center justify-between'>
        <div className='flex items-center gap-2 space-x-2 font-jakarta'>
          <FontAwesomeIcon icon={faClock} />
          <span className='font-jakarta text-sm text-white'>
            {timeSinceCreationString}
          </span>
        </div>
        {isExpanded ? (
          <EyeOff
            width={14}
            height={14}
            className='cursor-pointer text-white'
            onClick={() => setIsExpanded(false)}
          />
        ) : (
          <Eye
            width={14}
            height={14}
            className='cursor-pointer text-white'
            onClick={() => setIsExpanded(true)}
          />
        )}
      </div>
      {isExpanded && (
        <div className='grid w-full max-w-[400px] grid-cols-2 justify-center gap-3 place-self-center pt-4'>
          {images.map((img, index) => (
            <div className={`aspect-[16/11] w-full cursor-pointer`} key={index}>
              <ResultsGenerationImage
                imagePath={img}
                importImage={importImage}
                className='h-full w-full rounded-lg object-cover'
              />
            </div>
          ))}
        </div>
      )}
    </motion.div>
  );
};
