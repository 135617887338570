import { ExpandableCard } from './ExpandableCard';

import { useStore } from '@nanostores/react';
import { $generations } from '../../../../store/generations';

import { LayoutGroup } from 'framer-motion';
import { motion } from 'framer-motion';

type ResultsProps = {
  importImage: (img: string) => void;
};

export type GenerationFromAPI = {
  createdAt: string;
  urls: string[];
  prompt: string;
};

const Results = ({ importImage }: ResultsProps) => {
  const generations = useStore($generations);

  return (
    <div className='font-si relative grid h-full w-full grid-rows-[min-content_1fr_min-content] gap-6 overflow-auto rounded-[32px] bg-fnac-dark-gray p-5 py-8 font-ccsign text-[14px]'>
      <motion.div className='flex h-full w-full flex-col gap-4'>
        <LayoutGroup>
          {generations.map(({ createdAt, urls }, index) => {
            return (
              <ExpandableCard
                key={index}
                genId={''}
                createdAt={createdAt}
                images={urls}
                importImage={importImage}
                initialExpand={index === 0}
                index={generations.length - index}
              />
            );
          })}
        </LayoutGroup>
      </motion.div>
    </div>
  );
};

export default Results;
