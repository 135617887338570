import React, { useEffect, useState } from "react";
import { ArrowDown, ArrowUp, Calendar, Heart } from "lucide-react";
import timeStampParam from "../../../helpers/api";
import Card from "./Card";
import { useKeycloak } from "@react-keycloak/web";
import { useTranslation } from "react-i18next";

import { ModeratorCard } from "./ModeratorCard";
import { ReportModal } from "../modal/ReportModal";
import { useInView } from "framer-motion";
import { faTrophy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";



import { Mode, Sorter, Submission, } from "../../../types";


type Order = "asc" | "desc";

const orderToggler: Record<Order, Order> = {
  asc: "desc",
  desc: "asc",
};


const modeToggler: Record<Mode, Mode> = {
  normal: "moderate",
  moderate: "normal",
};

type GalleryProps = {
  title: string;
  isFnacModerator?: boolean;
  handlePropagateRefresh?: () => void;
  isFnacDemo?: boolean;
};

export type CardType = {
  id: string;
  src: string;
  author: string;
  date: string;
  likes: number;
  hasMyVote: boolean;

};


export const Gallery: React.FC<GalleryProps> = ({
  title,
  isFnacModerator = false,
  isFnacDemo = false,
  handlePropagateRefresh,
}: GalleryProps) => {
  const { keycloak } = useKeycloak();
  const { t } = useTranslation();

  const [displayedCards, setDisplayedCards] = useState<CardType[]>([]);

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);

  const [sorter, setSorter] = useState<Sorter>("date");
  const [likeOrder, setLikeOrder] = useState<Order>("asc");
  const [dateOrder, setDateOrder] = useState<Order>("desc");
  const [mode, setMode] = useState<Mode>("normal");
  const [isReplacement, setIsReplacement] = useState<boolean>(true);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const [isReportModalOpen, setIsReportModalOpen] = useState<boolean>(false);
  const [reportSubmissionId, setReportSubmissionId] = useState<string>("");

  /* Lazy loading on scroll */
  const loadMoreRef = React.useRef<HTMLDivElement>(null);
  const isInView = useInView(loadMoreRef)

  useEffect(() => {
    if (isInView && !isLoading && page < totalPage) {
      console.log("Load more");
      loadMore();
    }
  }, [isInView]);

  useEffect(() => {
    if (!keycloak.token) return;

    setIsLoading(true);

    const order = sorter === "date" ? dateOrder : likeOrder;
    fetch(
      `${import.meta.env.VITE_API_URL
      }/event/fnac70/submissions${timeStampParam}&page=${page}&order=${order}&sorter=${sorter}&mode=${mode}`,
      {
        headers: { authorization: `Bearer ${keycloak.token}` },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.error) return setIsError(true);

        if (isReplacement) {
          setDisplayedCards(
            data.submissions.map((sub: Submission) => ({
              ...sub,
              hasMyVote: sub.votes.length > 0,
            }))
          );
        } else {
          setDisplayedCards((prev) => [...prev, ...data.submissions]);
          setIsReplacement(true);
        }

        /*     setDisplayedCards((prev) => [...prev, ...fakeCards]); */
        setTotalPage(data.totalPages);
      })
      .catch(() => setIsError(true))
      .finally(() => setIsLoading(false));
  }, [keycloak, page, likeOrder, dateOrder, sorter, mode]);

  const toggleLikeFilter = () => {
    setDateOrder("asc");
    setLikeOrder(orderToggler[likeOrder]);
    setSorter("like");
    setPage(1);
  };

  const toggleDateFilter = () => {
    setLikeOrder("asc");
    setDateOrder(orderToggler[dateOrder]);
    setSorter("date");
    setPage(1);
  };

  const toggleSubmissionList = () => {
    setMode(modeToggler[mode]);
    setPage(1);
  };

  const loadMore = () => {
    setIsReplacement(false);
    setPage((prev) => prev + 1);
  };

  const handleOnLike = (id: string) => {
    fetch(new URL("/event/fnac70/vote", import.meta.env.VITE_API_URL), {
      headers: {
        authorization: `Bearer ${keycloak.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id }),
      method: "POST",
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          return setIsError(true);
        }
        const { event } = data.event;
        setDisplayedCards((prev) =>
          prev.map((card) =>
            card.id === id
              ? {
                ...card,
                likes: event === "VOTE" ? card.likes + 1 : card.likes - 1,
                hasMyVote: !card.hasMyVote,
              }
              : card
          )
        );
      });
  };

  // TODO FNAC @Aurel: Open a popin to add a comment to the report
  const handleOnFlag = (id: string) => {
    setIsReportModalOpen(true);
    setReportSubmissionId(id);
  };

  const handleOnReportModalClose = () => {
    setIsReportModalOpen(false);
    setReportSubmissionId("");
  };

  const handleOnReportModalSubmit =
    (id: string) => (comment: string) => (e: React.MouseEvent) => {
      e.preventDefault();

      fetch(new URL("/event/fnac70/report", import.meta.env.VITE_API_URL), {
        headers: {
          authorization: `Bearer ${keycloak.token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id,
          comment,
          fromModerator: isFnacModerator,
        }),
        method: "POST",
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.error) return setIsError(true);

          if (mode === "normal") {
            setDisplayedCards((prev) => prev.filter((card) => card.id !== id));
          }

          if (handlePropagateRefresh) {
            handlePropagateRefresh();
          }
        })
        .finally(() => {
          handleOnReportModalClose();
        });
    };

  const handleOnUnflag = (id: string) => (e: React.MouseEvent) => {
    e.preventDefault();
    console.log("Unflag Event on Card:", id);

    fetch(new URL("/event/fnac70/unreport", import.meta.env.VITE_API_URL), {
      headers: {
        authorization: `Bearer ${keycloak.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id,
      }),
      method: "POST",
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) return setIsError(true);

        if (mode === "moderate") {
          setDisplayedCards((prev) => prev.filter((card) => card.id !== id));
        }

        if (handlePropagateRefresh) {
          handlePropagateRefresh();
        }
      });
  };

  const handleOnBlock = (id: string) => (e: React.MouseEvent) => {
    e.preventDefault();
    console.log("Block Event on Card:", id);

    fetch(new URL("/event/fnac70/block", import.meta.env.VITE_API_URL), {
      headers: {
        authorization: `Bearer ${keycloak.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id,
      }),
      method: "POST",
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) return setIsError(true);

        if (mode === "moderate") {
          setDisplayedCards((prev) => prev.filter((card) => card.id !== id));
        }

        if (handlePropagateRefresh) {
          handlePropagateRefresh();
        }
      });
  };

  // TODO FNAC @Aurel: Open a popin to select Social Network option
  const handleOnShare = (id: string) => (e: React.MouseEvent) => {
    e.preventDefault();
    console.log("Share Event on Card:", id);
  };

  return (
    <div className="flex flex-col text-white p-4 md:p-8 xl:max-w-screen-2xl gap-6 ">
      <ReportModal
        isOpen={isReportModalOpen}
        onSubmit={handleOnReportModalSubmit(reportSubmissionId)}
        onClose={handleOnReportModalClose}
      />
      <h3 className="z-10 top-0 sticky flex flex-col md:flex-row w-full items-center md:justify-between gap-4 bg-fnac-black py-4 text-center">
        <p className="font-jakarta text-3xl font-extrabold uppercase sm:flex">{title}</p>
        {/* SORTING */}
        <div className="flex w-full  items-center space-x-3 justify-center md:justify-end">
          {isFnacModerator && (
            <button
              className={`hidden md:block justify-center items-center space-x-1 font-jakarta  text-sm text-center ${mode === "normal" ? "bg-fnac-dark-gray" : "bg-fnac-mustard"
                } ${mode === "normal" ? "text-fnac-mustard" : "text-fnac-black"
                } p-3 rounded-md`}
              onClick={toggleSubmissionList}
            >
              {mode === "normal"
                ? t("Fnac.Gallery.Moderate")
                : t("Fnac.Gallery.Visible")}
            </button>
          )}
          <p className="hidden sm:block">{t("Fnac.Gallery.Sort")}</p>
          <button
            className="flex items-center justify-center space-x-1 rounded-md bg-fnac-dark-gray p-3 text-center font-jakarta text-sm"
            onClick={toggleLikeFilter}
          >
            <Heart width={12} height={12} />
            <span>Likes</span>
            {likeOrder === "asc" ? (
              <ArrowUp width={12} height={12} />
            ) : (
              <ArrowDown width={12} height={12} />
            )}
          </button>
          <button
            className="flex items-center justify-center space-x-1 rounded-md bg-fnac-dark-gray p-3 text-center font-jakarta text-sm"
            onClick={toggleDateFilter}
          >
            <Calendar width={12} height={12} />
            <span>Date</span>
            {dateOrder === "asc" ? (
              <ArrowUp width={12} height={12} />
            ) : (
              <ArrowDown width={12} height={12} />
            )}
          </button>
        </div>
      </h3>
      {isError && <p className="mb-3 text-red">{t("Fnac.Gallery.Error")}</p>}

      {displayedCards.length === 0 && isFnacModerator && mode === "normal" && (
        <p>{t("Fnac.Gallery.NoSubmissionVisibleModerator")}</p>
      )}
      {displayedCards.length === 0 &&
        isFnacModerator &&
        mode === "moderate" && (
          <p>{t("Fnac.Gallery.NoSubmissionReportedModerator")}</p>
        )}
      {displayedCards.length === 0 && !isFnacModerator && (
        <p className="text-center md:text-left text-sm mt-6">
          {t("Fnac.Gallery.NoSubmission")}
          <br />
          <a href="/creator" className="mt-6 block bg-fnac-mustard not-italic text-fnac-black p-4 rounded-xl font-bold text-base md:inline-block md:px-8">
            {t("Fnac.Contest.Participate")} &nbsp; <FontAwesomeIcon icon={faTrophy} width={12} height={12} color="#13100D" />
          </a>
        </p>
      )}

      {/* CARD GRID */}
      <div className="grid w-full gap-5 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4">
        {displayedCards.map(
          (card: CardType, i: number) => {
            const { id } = card;
            return mode === "normal" ? (
              <Card
                key={i}
                card={card}
                isVoteDisabled={isFnacModerator || isFnacDemo}
                isFnacModerator={isFnacModerator}
                onLike={handleOnLike}
                onShareHandler={handleOnShare(id)}
                onFlag={handleOnFlag}
              />
            ) : (
              <ModeratorCard
                key={i}
                card={card}
                isVoteDisabled={isFnacModerator || isFnacDemo}
                onLike={handleOnLike}
                onShareHandler={handleOnShare(id)}
                onFlag={handleOnFlag}
                isFnacModerator={isFnacModerator}
                onUnflagHandler={handleOnUnflag(id)}
                onBlockHandler={handleOnBlock(id)}
              />
            );
          }
        )}
      </div>

      {/* LOAD MORE */}
      <div className="" ref={loadMoreRef}></div>
      {page < totalPage && (
        <button

          className="mx-auto mt-4 block rounded-md border p-2 hover:bg-primary hover:text-grey"
          onClick={loadMore}
          disabled={isLoading}
        >
          {t("Generations.LoadMore")}
        </button>
      )}
    </div>
  );
};
