import { motion } from "framer-motion";
import { Loader2 } from "lucide-react";
import { memo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const Loading = memo(() => {
    const { t } = useTranslation();
    const [showWait, setShowWait] = useState<boolean>(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowWait(true);
        }, 1000);

        return () => {
            clearTimeout(timer);
            setShowWait(false);
        };
    }, []);

    return (
        <motion.div layout className=" bg-fnac-dark-gray font-si rounded-[18px] p-5 text-[14px] flex flex-col items-center">
            <Loader2 className="animate-spin text-primary mx-auto" size={30} />
            <p className="mt-3 font-mono text-grey-200">{t("Beink.Loading.title")}</p>
            {showWait && <p className="italic mt-5">{t("Beink.Loading.waiting")}</p>}
        </motion.div>
    );
});

export default Loading;