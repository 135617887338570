import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeft,
  faMagicWandSparkles,
  faMessage,
  faPencil,
} from '@fortawesome/free-solid-svg-icons';
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Dispatch, SetStateAction, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { BeinkPhase } from '../../../../types';
import { useTranslation } from 'react-i18next';

const Help = ({
  setIsReviewFormOpen,
  setPhase,
}: {
  setIsReviewFormOpen: Dispatch<SetStateAction<boolean>>;
  setPhase: Dispatch<SetStateAction<BeinkPhase>>;
}) => {
  const { t } = useTranslation();
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);

  const isASectionActive = !(currentSectionIndex === 0);

  const sections = [
    {
      title: 'Comment ça marche ?',
      index: 1,
    },
  ];

  const variants = {
    initial: {
      x: 0,
      transition: {
        duration: 0.3,
        ease: 'linear',
      },
    },
    animate: {
      x: '-50%',
      transition: {
        duration: 0.3,
        ease: 'linear',
      },
    },
  };

  const titleClasses =
    'title text-sm text-center font-jakarta text-[12px] font-bold flex items-center gap-3';

  return (
    <div
      className={
        'font-si h-full w-full overflow-hidden rounded-[32px] bg-fnac-dark-gray text-[12px]'
      }>
      <motion.div
        className='grid h-full w-[200%] grid-cols-2'
        variants={variants}
        initial='initial'
        animate={isASectionActive ? 'animate' : 'initial'}>
        {/* Section Menu */}
        <div className='menu flex h-full w-full flex-col justify-between'>
          <div className='p-5'>
            {/* Title */}
            <div className={titleClasses}>
              <div className='h-[42px] w-[42px]'>
                <FontAwesomeIcon
                  className='cursor-pointer rounded-md bg-grey-400 p-4'
                  icon={faArrowLeft}
                  onClick={() => setPhase(BeinkPhase.Generate)}
                />
              </div>
              <div className='text-[20px]'>Guide d'utilisation</div>
            </div>
            <div className='desc my-5 text-sm'>
              Retrouvez ici toute l'aide nécéssaire pour utiliser Beink Dream
              facilement
            </div>
            {/* Menu Nav */}
            {sections.map(({ title, index }) => {
              return (
                <div
                  key={index}
                  onClick={() => setCurrentSectionIndex(index)}
                  className='my-2 flex cursor-pointer items-center justify-between gap-2 rounded-[12px] bg-grey-400 p-4'>
                  <div className=''>
                    <FontAwesomeIcon icon={faCircleQuestion} className='mx-2' />
                    <span className='py-4 text-sm'>{title}</span>
                  </div>

                  <FontAwesomeIcon icon={faArrowRight} />
                </div>
              );
            })}
            <div
              className='my-2 flex cursor-pointer items-center justify-between gap-2 rounded-[12px] bg-grey-400 p-4 text-white'
              onClick={() => {
                setIsReviewFormOpen(true);
              }}>
              <div className=''>
                <FontAwesomeIcon icon={faCircleQuestion} className='mx-2' />
                <span>{t('Beink.ReviewLink')}</span>
              </div>
            </div>
          </div>
          <div />
        </div>

        {/* Current section */}
        <div className='section flex flex-col items-center p-5'>
          {/* Section Title */}
          {currentSectionIndex != 0 && (
            <>
              <div className='title text- flex items-center gap-3 place-self-start pb-4'>
                {/* Back */}
                <div className='h-[42px] w-[42px]'>
                  <FontAwesomeIcon
                    className='cursor-pointer rounded-md bg-grey-400 p-4'
                    icon={faArrowLeft}
                    onClick={() => setCurrentSectionIndex(0)}
                  />
                </div>
                <div className={titleClasses}>
                  <span className='text-xl'>
                    {sections[currentSectionIndex - 1].title}
                  </span>
                </div>
              </div>
              {/* Section Content */}
              {currentSectionIndex === 1 && <HowToUse />}
            </>
          )}
        </div>
      </motion.div>
    </div>
  );
};

const HowToUse = () => {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const steps = [
    {
      index: 0,
      title: 'Je dessine sur le tableau',
      icon: faPencil,
      image: 'v2/tuto-step-1.mp4',
      desc: "Puis j'utilise les outils pour personnaliser mon dessin ou mon image",
    },
    {
      index: 1,
      title: 'Je décris mon idée',
      icon: faMessage,
      image: 'v2/tuto-step-2.mp4',

      desc: "En une phrase simple et je choisis le style d'image que je veux générer",
    },
    {
      index: 2,
      title: 'Je générère mon idée',
      icon: faMagicWandSparkles,
      image: 'v2/tuto-step-3.mp4',

      desc: "Je selectionne mon dessin et je clique sur Générer. Je continue d'améliorer mon idée si je le souhaite",
    },
  ];

  const buttonClasses =
    '!h-[40px] !w-[40px] !flex cursor-pointer !items-center !justify-center !m-0 !rounded-full !bg-white !text-grey-600';

  return (
    <div className='w-full overflow-hidden rounded-[16px] bg-grey-400 p-4'>
      {/* Carousel */}
      <AnimatePresence mode='wait'>
        <motion.div
          key={currentStepIndex}
          initial={{ x: 100, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: -100, opacity: 0 }}
          transition={{ duration: 0.2 }}
          className='flex flex-col gap-4 p-4'>
          <div className='title flex flex-col items-center gap-[8px] py-[12px] font-jakarta text-[16px]'>
            <FontAwesomeIcon
              icon={steps[currentStepIndex].icon}
              className='scale-150'
            />
            <span>{steps[currentStepIndex].title}</span>
          </div>
          <video
            className='rounded-[10px]'
            autoPlay
            src={steps[currentStepIndex].image}
          />
          <div className='desc text-center text-[14px]'>
            {steps[currentStepIndex].desc}
          </div>
        </motion.div>
      </AnimatePresence>

      {/* Carousel Nav */}
      <div className='flex items-center justify-between p-5'>
        <button
          className={buttonClasses}
          onClick={() => setCurrentStepIndex(currentStepIndex - 1)}
          disabled={currentStepIndex === 0}>
          <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
        </button>
        <div className='flex items-center gap-2'>
          {steps.map(({ index }) => {
            return (
              <div
                key={index}
                className={
                  currentStepIndex === index
                    ? 'h-[4px] w-[4px] rounded-full bg-white'
                    : 'h-[4px] w-[4px] rounded-full bg-grey-200'
                }
              />
            );
          })}
        </div>
        <button
          className={buttonClasses}
          onClick={() => setCurrentStepIndex(currentStepIndex + 1)}
          disabled={currentStepIndex === steps.length - 1}>
          <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>
        </button>
      </div>
    </div>
  );
};

export default Help;
