import { LayoutGroup } from 'framer-motion';
import AttemptsCounter from './AttemptsCounter';

type GenerateProps = {
  children: React.ReactNode;
};
export const Generate = function ImagineFnac({ children }: GenerateProps) {
  return (
    <div className='prompt-form font-si flex h-full w-full flex-col items-center justify-between gap-3 overflow-auto rounded-[32px] bg-fnac-dark-gray p-5 text-[14px]'>
      <LayoutGroup>
        <div className='flex h-full w-full flex-col gap-3 overflow-auto'>
          {children}
        </div>
        <AttemptsCounter className={'self-center'} />
      </LayoutGroup>
    </div>
  );
};
