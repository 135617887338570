import { Dispatch, memo, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { BeinkPhase } from "../../../types";
import { faWandMagicSparkles, faImage, faCircleQuestion, faHouse, faUpRightAndDownLeftFromCenter, faDownLeftAndUpRightToCenter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { motion } from "framer-motion";
import { NavLink } from "react-router-dom";
import { LanguageSelector } from "../../LanguageSelector";

const PanelNavigation = memo(function ({
    clickAction,
    currentPhase,
    isPanelOpen,
    setIsPanelOpen,
}: {
    clickAction: Dispatch<SetStateAction<BeinkPhase>>;
    currentPhase: number;
    isPanelOpen: boolean;
    setIsPanelOpen: Dispatch<SetStateAction<boolean>>;

}) {
    const { t } = useTranslation();
    const navs = [
        {
            name: t("Fnac.Panel.Generate"),
            phase: BeinkPhase.Generate,
            icon: faWandMagicSparkles,
        },
        {
            name: t("Beink.Tabs.results"),
            phase: BeinkPhase.Results,
            icon: faImage,
        },
        {
            name: null,
            phase: BeinkPhase.Help,
            icon: faCircleQuestion,
            styles: "px-3 py-5 mx-0 flex items-center gap-2"
        },
    ];

    return (
        <div className="mb-2 relative w-full">
            <LanguageSelector />
            <motion.div
                animate={isPanelOpen ? { borderRadius: "24px 24px 0 0" } : { borderRadius: "24px" }}
                transition={{ duration: 0.15 }}
                className={`grid items-center p-4 pb-4 !rounded-tl-[24px] !rounded-tr-[24px]  pointer-events-auto bg-fnac-dark-gray    relative z-10 gap-3 `}>

                <div className="top-menu grid grid-cols-[min-content_1fr_min-content] h-full items-center justify-center">
                    <NavLink to="/">
                        <FontAwesomeIcon icon={faHouse} className="bg-grey-400 p-3 rounded-md"></FontAwesomeIcon>
                    </NavLink>

                    <NavLink to="/" className="h-full w-full bg-[url('/v2/beink-logo.svg')] bg-contain bg-no-repeat bg-center cursor-pointer">

                    </NavLink>


                    <motion.div className="" whileHover={{ scale: 1.1 }} onClick={() => {
                        setIsPanelOpen(!isPanelOpen);
                    }}>
                        {isPanelOpen ?
                            <FontAwesomeIcon icon={faDownLeftAndUpRightToCenter} className={`bg-fnac-dark-gray p-3 rounded-md cursor-pointer ${isPanelOpen ? "bg-[#0EC88A]" : ""} transition-colors`}
                            />
                            :
                            < FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} className={`bg-fnac-dark-gray p-3  rounded-md cursor-pointer ${isPanelOpen ? "bg-[#0EC88A]" : ""} transition-colors`}
                            />
                        }
                    </motion.div>
                </div>


            </motion.div >

            <motion.div

                animate={!isPanelOpen ? { y: "-100%", opacity: 0 } : { y: 0, opacity: 1 }}
                transition={{ duration: 0.2 }}
                className={"w-full flex text-sm rounded-b-[24px] p-4 bg-fnac-dark-gray z-0 pt-0"}>
                <div className="flex w-full bg-grey-700 rounded-2xl p-2">

                    {navs.map(({ name, icon, phase, styles }) => (
                        <div
                            key={name}
                            onClick={() => {
                                clickAction(phase);
                            }}
                            className={`relative cursor-pointer flex-grow text-center justify-center rounded-[14px] h-[52px] ${styles || "px-5 flex  items-center gap-2"}`}
                        >
                            {currentPhase === phase &&
                                <motion.div layoutId="selected"
                                    transition={{ duration: 0.1 }}

                                    className="absolute bg-grey-400 h-full w-full z-0 left-0 rounded-[14px]"></motion.div>
                            }
                            <div className="z-10 flex  items-center gap-2">

                                <FontAwesomeIcon icon={icon}></FontAwesomeIcon>
                                {name}
                            </div>

                        </div>
                    ))}
                </div>
            </motion.div>

        </div >
    );
});

export default PanelNavigation;