import React, {
  useEffect,
  useState,
} from "react";

import useGenerate from "../../../hooks/useGenerate";
import useAppContext from "../../../hooks/useAppContext";

import "../../../styles/beink.scss";

import { BeinkPhase, DesignStyle, Generation, GenerationDTO, SelectedGenerationDTO } from "../../../types";
import { useKeycloak } from "@react-keycloak/web";
import timeStampParam from "../../../helpers/api";

import { intlDateTimeShortFormater } from "../../../helpers/dateManipulation";

import PanelNavigation from "./PanelNavigation";
import Help from "./Help/Help";
import { ImagineFnac } from "./Imagine/ImagineFnac";

import Results from "./Results/Results";
import Loading from "./Imagine/Loading";
import { AnimatePresence, motion } from "framer-motion";



type BeinkPanelNextProps = {
  isFnacAdherent?: boolean;
  isFnacUser?: boolean;
  onImport: (url: string) => void;
  onGetSelection: () => string | null;
  hasObjectSelected: boolean;
  onSubmissionStart: ({ genId, img }: { genId: string; img: string }) => void;
  hasAlreadySubmitted: boolean;
};



const generationsToUrls = (generations: Generation[]) => {
  return generations.map(
    ({ id, createdAt, generatedImagesUrl }: Generation) => ({
      genId: id,
      date: intlDateTimeShortFormater.format(new Date(createdAt)),
      images: generatedImagesUrl,
    })
  );
};

export const BeinkPanelNext: React.FC<BeinkPanelNextProps> = ({
  isFnacAdherent = false,
  isFnacUser = false,
  onImport,
  onGetSelection,
  hasObjectSelected,
  onSubmissionStart,
  hasAlreadySubmitted,
}: BeinkPanelNextProps) => {
  const { keycloak } = useKeycloak();

  let { generationsLeft } = useAppContext();

  if (isFnacUser && !isFnacAdherent) {
    generationsLeft = 0;
  }

  const { generateImage, isLoading, error, proposals, proposalsWithGenId } =
    useGenerate();

  const [isPanelOpen, setIsPanelOpen] = useState(true);

  const [phase, setPhase] = useState<BeinkPhase>(BeinkPhase.Generate);
  const [imagineDescription, setImagineDescription] = useState("");
  const [graphicStyle, setGraphicStyle] = useState<DesignStyle>(
    DesignStyle.HandSketch
  );

  const [generations, setGenerations] = useState<GenerationDTO[]>([]);
  const [selectedGen, setSelectedGen] = useState<string>("");
  const [selectedImage, setSelectedImage] = useState<string>("");

  useEffect(() => {
    if (!keycloak.token) return;

    fetch(
      `${import.meta.env.VITE_API_URL
      }/my-generations${timeStampParam}&limit=${20}`,
      {
        headers: { authorization: `Bearer ${keycloak.token}` },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.error) return;
        setGenerations(generationsToUrls((data.generations ?? []).slice(0, 5)));
      });
  }, [keycloak, proposals]);

  useEffect(() => {
    if (proposals.length && proposalsWithGenId) {
      setPhase(BeinkPhase.Results);
    }
  }, [proposals]);

  const handleSelectCard = ({ genId, img }: SelectedGenerationDTO) => {
    setSelectedGen(genId);
    setSelectedImage(img);
  };

  const handleSubmit = (e: React.MouseEvent) => {
    e.preventDefault();

    onSubmissionStart({ genId: selectedGen, img: selectedImage });
  };



  return (
    <>

      <div className={`overflow-hidden  absolute z-50 right-0   bg-transparent h-dvh  grid grid-rows-[min-content_1fr] 
      items-center p-2 w-full xs:w-[380px] ${isPanelOpen ? "" : "pointer-events-none"}`}>

        <PanelNavigation
          clickAction={setPhase}
          currentPhase={phase}
          isPanelOpen={isPanelOpen}
          setIsPanelOpen={setIsPanelOpen}
        />

        {isLoading ? (
          <Loading />
        ) : (

          <motion.div
            className="h-full w-full overflow-hidden"
            animate={!isPanelOpen ? { y: "-10%", opacity: 0 } : { y: 0, opacity: 1 }}
          >
            <AnimatePresence mode="wait" key={phase}>
              <motion.div className="h-full w-full overflow-hidden"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2, ease: "linear" }}

              >
                {phase === BeinkPhase.Generate && (
                  <>
                    <ImagineFnac
                      graphicStyle={graphicStyle}
                      setGraphicStyle={setGraphicStyle}
                      input={imagineDescription}
                      setInput={setImagineDescription}
                      onGetSelection={onGetSelection}
                      generateImage={generateImage}
                      isEnabled={hasObjectSelected}
                      error={error}
                      generationsLeft={generationsLeft}
                    />
                  </>
                )}

                {phase === BeinkPhase.Results && (
                  <Results
                    generations={generations}
                    importFnct={onImport}
                    isSubmissionVisible={isFnacUser}
                    isSubmitEnabled={!!selectedImage}
                    hasAlreadySubmitted={hasAlreadySubmitted}
                    handleSelectCard={handleSelectCard}
                    handleSubmit={handleSubmit}
                  />
                )}

                {phase === BeinkPhase.Help && <Help setPhase={setPhase} />}
              </motion.div>
            </AnimatePresence>
          </motion.div>

        )}
      </div>
    </>
  );
};






