import { memo } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Logo from "../components/svg/Logo";
import { ExternalLink, ChevronLeft } from "lucide-react";

const Header = memo(() => {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <header className="bg-grey-400 flex gap-4 justify-between items-center py-3 px-6 fixed z-100 top-0 w-full">
      <div>
        <a href="/">
          <Logo className="fill-primary h-[60px]" />
        </a>
      </div>

      {location.pathname === "/" ? (
        <NavLink
          to="/creator"
          className="py-2 px-6 space-x-2.5 rounded-full gradient-btn font-bold flex items-center gap-1"
        >
          <span>{t("Home.LaunchApp")}</span>
          <ExternalLink width={20} />
        </NavLink>
      ) : (
        <a
          href="/"
          className="py-2 px-6  flex items-center gap-2 hover:text-primary"
        >
          <ChevronLeft width={20} />
          <span>{t("Home.Nav.BackHome")}</span>
        </a>
      )}
    </header>
  );
});

export default Header;
