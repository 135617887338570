import { ButtonHTMLAttributes } from "react"

type GradientButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
    children: React.ReactNode
}

const GradientButton = ({ children, className, ...rest }: GradientButtonProps) => {
    return (
        <button {...rest} className={`beink-linear-gradient rounded-xl cursor-pointer ${className} 
        font-jakarta mt-2 flex items-center justify-center !p-4 !text-base !font-extrabold gap-2 w-full rounded-2xl !text-white `} >{children}</button>
    )
}

export default GradientButton