import { memo } from "react";
import { Trans } from "react-i18next";

const AttemptsCounter = memo(function ({
    attemptsCount,
    className,
}: {
    attemptsCount: number;
    className?: string;
}) {
    const clampAttemptsCount = Math.max(0, attemptsCount);

    return (
        <div className={className}>
            <Trans
                i18nKey="Beink.Attempts"
                count={attemptsCount}
                values={{ count: clampAttemptsCount }}
            />
        </div>
    );
});

export default AttemptsCounter;