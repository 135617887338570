import { useTranslation } from "react-i18next";
import { ShareBox } from "../social/ShareBox";
import { useEffect, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";

export const ChallengerHeaderCard: React.FC = () => {
  const { keycloak } = useKeycloak();
  const { t } = useTranslation();

  const [img, setImg] = useState<string>("/v2/my-card.png");
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!keycloak.token) return;

    fetch(new URL("/event/fnac70/submission", import.meta.env.VITE_API_URL), {
      headers: { authorization: `Bearer ${keycloak.token}` },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) return;

        setImg(data.url);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [keycloak]);

  if (loading)
    return;

  return (
    <div className="flex flex-col lg:flex-row justify-items-center items-center justify-between beink-radial-gradient max-w-[980px] rounded-3xl gap-6 p-4 md:p-8">
      <div className="flex flex-col text-xl text-center uppercase justify-center ">
        <h1 className="font-jakarta font-black text-white md:min-w-[190px] lg:text-left">
          {t("Fnac.MyContest.Entry")}
        </h1>
        <p className="font-ccsign font-bold text-fnac-mustard self-center text-center  md:self-start md:max-w-[160px] md:mx-auto lg:text-left lg:max-w-[190px]">
          {t("Fnac.MyContest.Fnac70")}
        </p>
      </div>
      <div className="flex flex-col gap-4 sm:flex-row w-full ">

        <div className="w-full self-center">
          <img
            className="md:max-w-64 max-h-40 md:w-64 md:h-40 md:min-w-64 md:min-h-40 rounded-xl m-auto"
            src={img}
            crossOrigin="anonymous"
          ></img>
        </div>
        
      </div>
      <ShareBox imageSrc={img} />
    </div>

    
  );
};
