import { Trophy } from "lucide-react";

import { useTranslation } from "react-i18next";
import { ExpandableCard } from "./ExpandableCard";
import { GenerationDTO, SelectedGenerationDTO } from "../../../../types";


const Results =
    ({
        generations,
        importFnct,
        isSubmissionVisible,
        isSubmitEnabled,
        hasAlreadySubmitted,
        handleSelectCard,
        handleSubmit,
    }: {
        generations: GenerationDTO[];
        importFnct: (img: string) => void;
        isSubmissionVisible: boolean;
        isSubmitEnabled: boolean;
        hasAlreadySubmitted: boolean;
        handleSelectCard: (imgWithGenId: SelectedGenerationDTO) => void;
        handleSubmit: (e: React.MouseEvent) => void;
    }) => {
        const { t } = useTranslation();



        return (
            <div className="w-full h-full relative bg-fnac-dark-gray font-si font-ccsign rounded-[32px] text-[14px] 
            p-5 grid grid-rows-[min-content_1fr_min-content]  gap-6 py-8">
                {/* Header */}
                <div className="flex items-center justify-center gap-2 mb-4">
                    <p className="text-base text-ceter jnter max-w-[200px]">Je sélectionne une image et j'améliore si nécéssaire</p>
                    <img className="h-[20px]" src={"v2/arrow.svg"} alt="" />
                </div>
                {/* Generations */}
                <div className="h-full w-full overflow-auto flex flex-col gap-4 ">
                    {generations.map(({ genId, date, images }, index) => (

                        <ExpandableCard
                            key={genId}
                            genId={genId}
                            date={date}
                            images={images}
                            importFnct={importFnct}
                            initialExpand={index === 0}
                            index={generations.length - index}

                            handleSelectCard={handleSelectCard}
                        />
                    ))}
                </div>
                {/* Submit */}
                {isSubmissionVisible && (
                    <button
                        className={`flex justify-center items-center space-x-2 font-jakarta font-extrabold text-base p-4 bg-fnac-mustard text-fnac-black rounded-2xl ${isSubmitEnabled && !hasAlreadySubmitted ? "" : "opacity-50"
                            }`}
                        onClick={handleSubmit}
                        disabled={!isSubmitEnabled || hasAlreadySubmitted}
                    >
                        <span className="">
                            {hasAlreadySubmitted
                                ? t("Fnac.Panel.AlreadySubmitted")
                                : t("Fnac.Panel.Submit")}
                        </span>
                        <Trophy width={14} height={14} />
                    </button>
                )}
            </div>
        );
    }


export default Results;