import { useEffect, useState } from 'react';

import { AnimatePresence, motion } from 'framer-motion';
import { BeinkPhase, UserRole } from '../../../types';
import { Generate } from './Generate/Generate';
import PanelNavigation from './PanelNavigation';
import Help from './Help/Help';
import Results from './Results/Results';
import Imagine from './Generate/Imagine';
import { Inpaint } from './Generate/Inpaint';

import { useKeycloak } from '@react-keycloak/web';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

type PanelProps = {
  importImage: (url: string) => void;
  exportSelectionFromCanvas: () => string | null;
  exportImageAndMasksOverlay: () => string[];
  hasObjectSelected: boolean;
  hasImageSelected: boolean;
  hasImageSelectedWithSmallSizes: boolean;
  hasDrawingSelected: boolean;
  hasSelectedDrawingsOutsideSelectedImages: boolean;
  isThemed?: boolean;
};

function Panel({
  importImage,
  exportSelectionFromCanvas,
  hasObjectSelected,
  hasImageSelected,
  exportImageAndMasksOverlay,
  hasImageSelectedWithSmallSizes,
  hasDrawingSelected,
  hasSelectedDrawingsOutsideSelectedImages,
}: PanelProps) {
  const [isPanelOpen, setIsPanelOpen] = useState<boolean>(true);
  const [phase, setPhase] = useState<BeinkPhase>(BeinkPhase.Generate);
  const [isReviewFormOpen, setIsReviewFormOpen] = useState<boolean>(false);

  const [lockGeneration, setLockGeneration] = useState<boolean>(false);

  const { keycloak } = useKeycloak();

  const userRoles: string[] = keycloak.realmAccess?.roles || [];
  const inpaintAllowed = userRoles.includes(UserRole.Inpaint);

  return (
    <div
      className={`absolute right-0 z-100 grid h-dvh w-full grid-rows-[min-content_1fr] items-center bg-transparent p-2 xs:w-[380px] ${isPanelOpen ? '' : 'pointer-events-none'}`}>
      <PanelNavigation
        clickAction={setPhase}
        currentPhase={phase}
        isPanelOpen={isPanelOpen}
        setIsPanelOpen={setIsPanelOpen}
      />
      <motion.div
        className='h-full w-full overflow-hidden'
        animate={
          !isPanelOpen ? { y: '-10%', opacity: 0 } : { y: 0, opacity: 1 }
        }>
        <AnimatePresence mode='wait' key={phase}>
          <motion.div
            className='h-full w-full overflow-hidden'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2, ease: 'linear' }}>
            {phase === BeinkPhase.Generate && (
              <Generate>
                <Imagine
                  exportSelectionFromCanvas={exportSelectionFromCanvas}
                  isAnObjectSelected={hasObjectSelected}

                  setPhase={setPhase}
                  setLockGeneration={setLockGeneration}
                  lockGeneration={lockGeneration}

                />
                {inpaintAllowed && (
                  <Inpaint
              
                    hasImageSelected={hasImageSelected}
                    hasDrawingSelected={hasDrawingSelected}
                    hasObjectSelected={hasObjectSelected}
                    exportImageAndMasksOverlay={exportImageAndMasksOverlay}
                    shouldWarnAboutMaskBoundaries={                      hasSelectedDrawingsOutsideSelectedImages                    }
                    shouldWarnAboutImageSizeAndQuality={                      hasImageSelectedWithSmallSizes                    }

                    setLockGeneration={setLockGeneration}
                    lockGeneration={lockGeneration}
                      setPhase={setPhase}
                  />
                )}
              </Generate>
            )}

            {phase === BeinkPhase.Results && (
              <Results importImage={importImage}></Results>
            )}

            {phase === BeinkPhase.Help && (
              <Help
                setPhase={setPhase}
                setIsReviewFormOpen={setIsReviewFormOpen}
              />
            )}
          </motion.div>
        </AnimatePresence>
      </motion.div>

      <ReviewPanel
        isOpen={isReviewFormOpen}
        closeAction={() => setIsReviewFormOpen(false)}
      />
    </div>
  );
}

export default Panel;

const ReviewPanel = ({
  isOpen,
  closeAction,
}: {
  isOpen: boolean;
  closeAction: () => void;
}) => {
  useEffect(() => {
    const handleIFrameMessage = (e: MessageEvent) => {
      if (e.data.action === 'submission-completed')
        setTimeout(() => {
          closeAction();
        }, 1_500);
    };
    window.addEventListener('message', handleIFrameMessage, false);
    return () => {
      window.removeEventListener('message', handleIFrameMessage);
    };
  }, []);

  if (!isOpen) return null;

  return (
    <>
      <div
        className='fixed inset-0 z-10 flex h-dvh w-screen flex-col items-center justify-center gap-2 overflow-hidden bg-grey-700 bg-opacity-70 p-5 backdrop-blur-sm'
        onClick={() => {
          closeAction();
        }}>
        <button
          onClick={(e) => {
            e.stopPropagation();
            closeAction();
          }}
          className='z-20 flex h-[40px] w-[40px] items-center justify-center rounded-full bg-grey-600 text-white hover:text-primary'>
          <FontAwesomeIcon icon={faTimes} />
        </button>

        <iframe
          src={`https://form.jotform.com/241342390042344`}
          className='z-20 h-full w-full max-w-[800px] rounded-t-md border-0'></iframe>
      </div>
    </>
  );
};
