import React from "react";
import { LittleStampShape } from "../auth/LittleStampShape";
import NonAdherentModal from "./NonAdherentModal";

interface ModalProps {
  isAllowedToParticipate: boolean
  isOpen: boolean;
  onClose: () => void;
}

export const WelcomeModal: React.FC<ModalProps> = ({ isAllowedToParticipate, isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    isAllowedToParticipate ? (
      <div
        className={`z-100 border border-hidden fixed inset-0 bg-fnac-gray bg-opacity-80 backdrop-blur-sm flex items-center justify-center p-4 ${isOpen ? "no-doc-scroll" : ""
          }`}
      >
        <div className="bg-fnac-light-gray bg-[url('/v2/fnac-70-pattern.png')] rounded-3xl  overflow-hidden max-w-3xl w-full">
          <div className="w-full h-full bg-fnac-light-gray bg-opacity-80  px-8 py-10 md:px-16 ">
            <div className="flex flex-col gap-2 items-center text-center">

              <LittleStampShape label="Concours" />
              <h2 className="text-2xl uppercase font-extrabold flex flex-col">
                <span className="font-jakarta font-extrabold text-fnac-black">
                  Et si vous deveniez l'artiste de la
                </span>
                <span className="font-ccsign font-bold text-fnac-mustard">
                  carte Fnac+ édition spéciale 70 ans ?
                </span>
              </h2>
              <p className="text-fnac-black  text-sm sm:text-base">
                Vous pourrez gagner un des nombreux lots Samsung et votre visuel
                imprimé sur la prochaine carte Fnac+ édition spéciale 70 ans !
              </p>



              <img src="/v2/kv-welcome.png" className="w-2/3 pb-4" />


              {!isAllowedToParticipate && (
                <p className="text-fnac-black text-base">
                  Le concours est réservé aux adhérent Fnac Plus et Fnac One, mais vous pouvez voter pour votre visuel préféré.
                </p>
              )}

              <button
                onClick={onClose}
                className="font-jakarta font-bold bg-fnac-mustard border border-hidden text-fnac-black py-4 px-8 rounded-xl"
              >
                Comment ça marche ?
              </button>
            </div>
          </div>
        </div>
      </div>) : <NonAdherentModal></NonAdherentModal>
  );
};
