import { faCaretUp, faLanguage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import i18next, { changeLanguage } from "i18next";

import { withTranslation } from "react-i18next";



export const LanguageSelector = withTranslation()(() => {
  return (
    <div className=" absolute flex gap-2 top-0 right-[100%] text-sm uppercase pointer-events-auto cursor-pointer rounded-2xl mr-2 bg-fnac-dark-gray" onClick={() => {
      const lang = i18next.language === "en" ? "fr" : "en";
      changeLanguage(lang);
    }}>
      <div className="p-4 flex gap-2 items-center text-white font-bold
      ">
        <FontAwesomeIcon icon={faLanguage} />
        {i18next.language}
        <FontAwesomeIcon icon={faCaretUp} />
      </div>
    </div >
  );
});
