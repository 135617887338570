import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useInpaint from '../../../../hooks/useInpaintv2';
import Accordion from './Accordion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBrush } from '@fortawesome/free-solid-svg-icons';
import GradientButton from '../../../lib/GradientButton';
import Loading from './Loading';
import { BeinkPhase } from '../../../../types';

type InpaintProps = {
  setPhase: Dispatch<SetStateAction<BeinkPhase>>;
  exportImageAndMasksOverlay: () => string[];
  shouldWarnAboutMaskBoundaries: boolean;
  shouldWarnAboutImageSizeAndQuality: boolean;
  hasImageSelected: boolean;
  hasDrawingSelected: boolean;
  hasObjectSelected: boolean;

  setLockGeneration: Dispatch<SetStateAction<boolean>>;
  lockGeneration: boolean;
};

export const Inpaint = function Inpaint({
  setPhase,
  exportImageAndMasksOverlay,
  shouldWarnAboutMaskBoundaries,
  shouldWarnAboutImageSizeAndQuality,

  hasImageSelected,
  hasDrawingSelected,
  hasObjectSelected,

  setLockGeneration,
  lockGeneration,
}: InpaintProps) {
  const { t } = useTranslation();

  const [inpaintDescription, setInpaintDescription] = useState('');
  const { isLoading, error, inpaintImage } = useInpaint();

  const requiredConditions =
    hasImageSelected &&
    hasDrawingSelected &&
    hasObjectSelected &&
    !lockGeneration &&
    inpaintDescription;

  const btnMessageFactory = () => {
    if (lockGeneration) {
      return 'Génération en cours';
    }
    if (!inpaintDescription) {
      return t('Beink.Inpaint.btnDisable');
    }
    if (shouldWarnAboutMaskBoundaries && shouldWarnAboutImageSizeAndQuality) {
      return `${t('Beink.Inpaint.btnWarning')} ${t(
        'Beink.Inpaint.btnWarningQuality'
      )} ${t('Beink.Inpaint.btnWarningAnd')} ${t(
        'Beink.Inpaint.btnWarningMask'
      )}`;
    }
    if (shouldWarnAboutMaskBoundaries) {
      return `${t('Beink.Inpaint.btnWarning')} ${t(
        'Beink.Inpaint.btnWarningMask'
      )}`;
    }
    if (shouldWarnAboutImageSizeAndQuality) {
      return `${t('Beink.Inpaint.btnWarning')} ${t(
        'Beink.Inpaint.btnWarningQuality'
      )}`;
    }

    return t('Beink.Inpaint.btnEnable');
  };

  const handleInpaint = async () => {
    const [dataImg, maskImg] = await exportImageAndMasksOverlay();
    if (dataImg && maskImg && inpaintDescription) {
      setLockGeneration(true);
      const success = await inpaintImage({
        prompt: inpaintDescription,
        scribble: dataImg,
        mask: maskImg,
      });

      if (success) setPhase(BeinkPhase.Results);

      setLockGeneration(false);
    }
  };

  return (
    <Accordion
      header={
        <div className='flex items-center gap-2'>
          <FontAwesomeIcon icon={faBrush} className='text-lg' />
          <span className='text-md font-jakarta font-bold'>
            {t('Beink.Modes.inpainting')}
          </span>
        </div>
      }>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {' '}
          {error && (
            <div
              className='alert-danger'
              role='alert'
              dangerouslySetInnerHTML={{ __html: error || '' }}></div>
          )}
          <div className='prompt-form flex w-full flex-col gap-3'>
            <label htmlFor='inpainting-prompt'>
              {t('Beink.Inpaint.prompt')}
            </label>
            <textarea
              className='!rounded-xl !bg-white !text-fnac-black'
              id='inpainting-prompt'
              placeholder={t('Beink.Inpaint.promptPlaceholder')}
              value={inpaintDescription}
              onChange={(e) => setInpaintDescription(e.target.value)}
            />
            <GradientButton
              onClick={handleInpaint}
              disabled={!requiredConditions}>
              {btnMessageFactory()}
            </GradientButton>
          </div>
        </>
      )}
    </Accordion>
  );
};
