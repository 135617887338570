import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Canvas from './Canvas';
import { dot, normalize, subtract, length } from './trig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy } from '@fortawesome/free-solid-svg-icons';

interface ModalProps {
  isOpen: boolean;
  img: string;
  onSubmit: (mergedImg: string, userImg: string) => void;
  onClose: () => void;
}

type Vector = {
  x: number;
  y: number;
};

export const SubmissionModal: React.FC<ModalProps> = ({
  isOpen,
  img,
  onSubmit,
  onClose,
}) => {
  const { t } = useTranslation();

  const contextRef = useRef<CanvasRenderingContext2D | null>(null);

  const imageRef = useRef<HTMLImageElement | null>(null);
  const maskRef = useRef<HTMLImageElement | null>(null);
  const overlayRef = useRef<HTMLImageElement | null>(null);

  const isMouseOverImage = useRef<boolean>(false);
  const isMouseOverRotateHandle = useRef<boolean>(false);
  const isMouseOverScaleHandle = useRef<boolean>(false);

  const vectorBetweenMouseAndImage = useRef<Vector>({ x: 0, y: 0 });
  const startAngle = useRef<number>(0);
  const scaleStartPoint = useRef<Vector>({ x: 0, y: 0 });

  const isDragging = useRef<boolean>(false);
  const isRotating = useRef<boolean>(false);
  const isScaling = useRef<boolean>(false);

  const [isFinalRender, setIsFinalRender] = useState<boolean>(false);

  const imagePosition = useRef<Vector>({
    x: -20,
    y: -20,
  });
  const imageRotation = useRef<number>(0);
  const imageScale = useRef<number>(0.32);

  //Load the images

  useEffect(() => {
    const image = new Image();
    imageRef.current = image;
    imageRef.current.src = img;
    image.crossOrigin = 'anonymous';

    const mask = new Image();
    maskRef.current = mask;
    maskRef.current.src = 'v2/fond-carte-test-1px.svg';
    maskRef.current.crossOrigin = 'anonymous';

    const overlay = new Image();
    overlayRef.current = overlay;
    overlayRef.current.src = 'v2/fnac_card_overlay.svg';
  }, [img]);

  const transformCanvasFromImage = (
    ctx: CanvasRenderingContext2D,
    width: number,
    height: number
  ) => {
    ctx.translate(imagePosition.current.x, imagePosition.current.y);
    ctx.rotate((imageRotation.current / 360) * Math.PI * 2);
    ctx.scale(imageScale.current, imageScale.current);
    ctx.translate(-width / 2, -height / 2);
  };
  const drawHandles = (
    ctx: CanvasRenderingContext2D,
    width: number,
    height: number
  ) => {
    if (!imageRef.current) return;
    //Draw the GUI
    ///Draw the borders of the image
    ctx.beginPath();
    ctx.lineWidth = 1;
    if (
      isMouseOverImage.current &&
      !isMouseOverRotateHandle.current &&
      !isRotating.current &&
      !isMouseOverScaleHandle.current
    ) {
      ctx.lineWidth = 4;
    }

    ctx.strokeStyle = 'rgba(252, 186, 3)';
    transformCanvasFromImage(ctx, width, height);

    const border = new Path2D();
    border.rect(0, 0, imageRef.current.width, imageRef.current.height);

    ctx.setLineDash([5]);
    ctx.stroke(border);

    ///Draw the rotate handle
    const rotateHandle = new Path2D();
    const rotateHandlePos = {
      x: imageRef.current.width / 2,
      y: imageRef.current.height / 2,
    };
    rotateHandle.arc(
      rotateHandlePos.x,
      rotateHandlePos.y,
      70 * (1 / imageScale.current),
      0,
      Math.PI * 2
    );
    rotateHandle.arc(
      rotateHandlePos.x,
      rotateHandlePos.y,
      60 * (1 / imageScale.current),
      0,
      Math.PI * 2
    );

    ctx.setLineDash([0]);

    ctx.lineWidth = 1 * (1 / imageScale.current);
    if (isMouseOverRotateHandle.current || isRotating.current) {
      ctx.lineWidth = 4 * (1 / imageScale.current);
    }

    ctx.stroke(rotateHandle);

    //Draw the scale handle
    //Scale Handles array
    const scaleHandles = [
      { x: 0, y: 0 },
      { x: imageRef.current.width, y: 0 },
      { x: 0, y: imageRef.current.height },
      { x: imageRef.current.width, y: imageRef.current.height },
    ];

    const scalePaths = [];

    const rectWidth = 20 * (1 / imageScale.current);
    for (let i = 0; i < scaleHandles.length; i++) {
      const scaleHandle = new Path2D();
      const scaleHandlePos = scaleHandles[i];
      scaleHandle.rect(
        scaleHandlePos.x - rectWidth / 2,
        scaleHandlePos.y - rectWidth / 2,
        rectWidth,
        rectWidth
      );
      scalePaths.push(scaleHandle);

      ctx.stroke(scaleHandle);
    }

    ctx.resetTransform();
  };
  const intersectionDetection = (
    ctx: CanvasRenderingContext2D,
    width: number,
    height: number,
    mousePosInCanvas: Vector
  ) => {
    if (!imageRef.current) return;
    //Draw the GUI
    ///Draw the borders of the image
    ctx.beginPath();
    ctx.lineWidth = 1;
    if (
      isMouseOverImage.current &&
      !isMouseOverRotateHandle.current &&
      !isRotating.current &&
      !isMouseOverScaleHandle.current
    ) {
      ctx.lineWidth = 4;
    }

    ctx.strokeStyle = 'rgba(252, 186, 3)';
    transformCanvasFromImage(ctx, width, height);

    const border = new Path2D();
    border.rect(0, 0, imageRef.current.width, imageRef.current.height);

    ///Draw the rotate handle
    const rotateHandle = new Path2D();
    const rotateHandlePos = {
      x: imageRef.current.width / 2,
      y: imageRef.current.height / 2,
    };
    rotateHandle.arc(
      rotateHandlePos.x,
      rotateHandlePos.y,
      70 * (1 / imageScale.current),
      0,
      Math.PI * 2
    );
    rotateHandle.arc(
      rotateHandlePos.x,
      rotateHandlePos.y,
      60 * (1 / imageScale.current),
      0,
      Math.PI * 2
    );

    ctx.lineWidth = 1 * (1 / imageScale.current);
    if (isMouseOverRotateHandle.current || isRotating.current) {
      ctx.lineWidth = 4 * (1 / imageScale.current);
    }

    //Draw the scale handles
    //Scale Handles array
    const scaleHandles = [
      { x: 0, y: 0 },
      { x: imageRef.current.width, y: 0 },
      { x: 0, y: imageRef.current.height },
      { x: imageRef.current.width, y: imageRef.current.height },
    ];

    const scalePaths = [];

    const rectWidth = 20 * (1 / imageScale.current);
    for (let i = 0; i < scaleHandles.length; i++) {
      const scaleHandle = new Path2D();
      const scaleHandlePos = scaleHandles[i];
      scaleHandle.rect(
        scaleHandlePos.x - rectWidth / 2,
        scaleHandlePos.y - rectWidth / 2,
        rectWidth,
        rectWidth
      );
      scalePaths.push(scaleHandle);
    }

    //Check for mouse over
    isMouseOverRotateHandle.current = ctx.isPointInPath(
      rotateHandle,
      mousePosInCanvas.x,
      mousePosInCanvas.y
    );
    isMouseOverImage.current = ctx.isPointInPath(
      border,
      mousePosInCanvas.x,
      mousePosInCanvas.y
    );

    isMouseOverScaleHandle.current = false;
    for (let i = 0; i < scalePaths.length; i++) {
      if (
        ctx.isPointInPath(scalePaths[i], mousePosInCanvas.x, mousePosInCanvas.y)
      ) {
        isMouseOverScaleHandle.current = true;
      }
    }

    ctx.resetTransform();
  };
  const drawCard = (
    ctx: CanvasRenderingContext2D,
    width: number,
    height: number
  ) => {
    if (!imageRef.current || !maskRef.current || !overlayRef.current) return;

    //Translating  and rotating the canvas
    transformCanvasFromImage(ctx, width, height);

    //Draw the image
    ctx.drawImage(
      imageRef.current,
      0,
      0,
      imageRef.current.width,
      imageRef.current.height
    );

    ctx.resetTransform();

    //Mask the image
    ctx.translate(ctx.canvas.width / 2, ctx.canvas.height / 2);
    ctx.translate(-maskRef.current.width / 2, -maskRef.current.height / 2);
    ctx.globalCompositeOperation = 'destination-atop';
    ctx.drawImage(
      maskRef.current,
      0,
      0,
      maskRef.current.width,
      maskRef.current.height
    );

    //Draw the overlay
    ctx.globalCompositeOperation = 'source-over';
    ctx.drawImage(
      overlayRef.current,
      0,
      0,
      overlayRef.current.width,
      overlayRef.current.height
    );

    ctx.resetTransform();
  };
  const setup = (ctx: CanvasRenderingContext2D) => {
    if (isFinalRender) return;
    imagePosition.current = {
      x: ctx.canvas.width / 2 - 20,
      y: ctx.canvas.height / 2 - 20,
    };
    contextRef.current = ctx;
  };
  const draw = (
    ctx: CanvasRenderingContext2D,
    mousePos: Vector,
    isMouseDown: boolean,
    isMouseUp: boolean
  ) => {
    if (!imageRef.current || !maskRef.current || !overlayRef.current) return;

    //Clear the canvas
    ctx.resetTransform();
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

    const width = imageRef.current.width;
    const height = imageRef.current.height;

    const mousePosInCanvas = {
      x: mousePos.x - ctx.canvas.offsetLeft,
      y: mousePos.y - ctx.canvas.offsetTop,
    };

    //Change the cursor
    ctx.canvas.style.cursor = 'default';

    if (isMouseOverImage.current) {
      ctx.canvas.style.cursor = 'move';
    }
    if (isMouseOverRotateHandle.current) {
      ctx.canvas.style.cursor = 'pointer';
    }
    if (isMouseOverScaleHandle.current) {
      ctx.canvas.style.cursor = 'nwse-resize';
    }

    //Intersection detection using the canvas API isPointInPath
    intersectionDetection(ctx, width, height, mousePosInCanvas);

    //Detecting start of dragging
    if (
      isMouseDown &&
      isMouseOverImage.current &&
      !isMouseOverRotateHandle.current &&
      !isMouseOverScaleHandle.current &&
      !isRotating.current &&
      !isScaling.current
    ) {
      isDragging.current = true;
      vectorBetweenMouseAndImage.current = {
        x: imagePosition.current.x - mousePosInCanvas.x,
        y: imagePosition.current.y - mousePosInCanvas.y,
      };
    }
    if (isDragging.current) {
      imagePosition.current.x =
        mousePosInCanvas.x + vectorBetweenMouseAndImage.current.x;
      imagePosition.current.y =
        mousePosInCanvas.y + vectorBetweenMouseAndImage.current.y;

      //Clamping the image position
      const BOUNDARY_X = 400;
      const BOUNDARY_Y = 300;

      if (imagePosition.current.x < BOUNDARY_X) {
        imagePosition.current.x = BOUNDARY_X;
      }
      if (imagePosition.current.y < BOUNDARY_Y) {
        imagePosition.current.y = BOUNDARY_Y;
      }
      if (imagePosition.current.x > ctx.canvas.width - BOUNDARY_X) {
        imagePosition.current.x = ctx.canvas.width - BOUNDARY_X;
      }
      if (imagePosition.current.y > ctx.canvas.height - BOUNDARY_Y) {
        imagePosition.current.y = ctx.canvas.height - BOUNDARY_Y;
      }
    }

    //Detecting start of rotating
    if (isMouseDown && isMouseOverRotateHandle.current) {
      isRotating.current = true;
      startAngle.current =
        (Math.atan2(
          mousePosInCanvas.y - imagePosition.current.y,
          mousePosInCanvas.x - imagePosition.current.x
        ) *
          180) /
          Math.PI +
        90 -
        imageRotation.current;
    }
    if (isRotating.current) {
      imageRotation.current =
        (Math.atan2(
          mousePosInCanvas.y - imagePosition.current.y,
          mousePosInCanvas.x - imagePosition.current.x
        ) *
          180) /
          Math.PI +
        90 -
        startAngle.current;
    }

    //Detecting start of scaling
    if (isMouseDown && isMouseOverScaleHandle.current) {
      isScaling.current = true;
      scaleStartPoint.current = mousePosInCanvas;
    }
    if (isScaling.current) {
      //Getting the vector from the mouse position to the starting point of the scale
      const scaleDelta = subtract(mousePosInCanvas, scaleStartPoint.current);
      let scaleDeltaLength = length(scaleDelta);
      const normalizedScaleDelta = normalize(scaleDelta);

      //Getting the vector from the starting point of the scale to the image center and normalizing
      const normalizedStartScale = normalize(
        subtract(scaleStartPoint.current, imagePosition.current)
      );

      //Dot product to get the angle between the two vectors
      const dotProduct = dot(normalizedStartScale, normalizedScaleDelta);

      //Re setting the scale start point
      scaleStartPoint.current = mousePosInCanvas;

      if (dotProduct < 0) {
        scaleDeltaLength = -scaleDeltaLength;
      }
      let newScale = imageScale.current + scaleDeltaLength / 1000;

      //Clamping the scale
      if (newScale < 0.25) {
        newScale = 0.25;
      }
      if (newScale > 1) {
        newScale = 1;
      }
      imageScale.current = newScale;
    }

    //End of dragging
    if (isMouseUp) {

      isDragging.current = false;
      isRotating.current = false;
      isScaling.current = false;
    }

    //Draw the card
    drawCard(ctx, width, height);

    //Draw the handles
/*     console.log('isFinalRender', isFinalRender); */
    if (!isFinalRender) {
      drawHandles(ctx, width, height);
    }

    ctx.resetTransform();
  };

  //Submit the final image when the user confirms
  useEffect(() => {
    console.log('isFinalRender', isFinalRender);
    if (isFinalRender) {
      handleSubmit();
    }
  }, [isFinalRender]);

  const handleSubmit = () => {
    console.log('handleSubmit');

    //Creating the final canvas
    const cardCanvas = document.createElement('canvas');
    const ctx = cardCanvas.getContext('2d');

    //Making it the size of the card
    cardCanvas.width = maskRef.current?.width || 0;
    cardCanvas.height = maskRef.current?.height || 0;

    if (!contextRef.current) {
      console.error('Failed to get 2D context for the final canvas');
      return;
    }

    //Centering the big canvas onto the final canvas
    const coordinates = {
      x: -(contextRef.current.canvas.width - cardCanvas.width) / 2,
      y: -(contextRef.current.canvas.height - cardCanvas.height) / 2,
    };

    //Draw the big canvas onto the final canvas
    if (ctx && contextRef.current) {
      ctx.drawImage(contextRef.current.canvas, coordinates.x, coordinates.y);
    }

    // Create a canvas for the selected generation
    const imageGenerationCanvas = document.createElement('canvas');
    const imageCtx = imageGenerationCanvas.getContext('2d');

    // Check if the canvas context is available
    if (!imageCtx) {
      console.error('Failed to get 2D context for the image canvas');
      return;
    }

    const imgElement = new Image();
    imgElement.crossOrigin = 'anonymous'; // Ensure CORS isn't an issue
    imgElement.src = img;

    imgElement.onload = () => {
      imageGenerationCanvas.width = imgElement.width;
      imageGenerationCanvas.height = imgElement.height;
      imgElement.crossOrigin = 'anonymous';

      // Draw the generation image on its own canvas
      imageCtx.drawImage(imgElement, 0, 0);

      //Saving the images and submitting them
      const mergedImage = cardCanvas.toDataURL('image/png');
      const selectedGeneration = imageGenerationCanvas.toDataURL('image/png');

      onSubmit(mergedImage, selectedGeneration);
    };
  };

  const resetCanvas = () => {
    console.log('resetCanvas');
    if (!contextRef.current) return;
    imagePosition.current = {
      x: contextRef.current.canvas.width / 2 - 20,
      y: contextRef.current.canvas.height / 2 - 20,
    };
    imageRotation.current = 0;
    imageScale.current = 0.32;
  };

  if (!isOpen) return null;

  return (
    <div
      className={`fixed inset-0 z-100 flex items-center justify-center overflow-hidden border border-hidden bg-fnac-gray bg-opacity-80 p-4 backdrop-blur-sm ${isOpen && 'no-doc-scroll'}`}>
      {/* CANVAS */}
      <div className='absolute left-0 top-0 z-80 flex h-screen w-screen items-center justify-center'>
        <Canvas draw={draw} setup={setup} />
      </div>

      {/* MODAL */}
      <div className='beink-radial-gradient flex w-full max-w-3xl flex-col items-center justify-center rounded-3xl p-4 py-8 md:py-12'>
        {/* TITLE */}
        <h2 className='mb-4 text-center uppercase'>
          <span className='font-jakarta text-lg font-extrabold text-white sm:text-3xl'>
            {t('Fnac.SubmissionModal.Title')}
          </span>{' '}
        </h2>
        {/* DESC */}
        <p className='mb-4 max-w-[400px] text-center text-sm font-normal text-white sm:text-base'>
          {t('Fnac.SubmissionModal.AdjustPosition')}
        </p>

        {/* CANVAS PLACEHOLDER */}
        <div className='h-60'></div>

        {/*BUTTONS*/}
        <div className='pointer-events-auto flex flex-col items-center justify-center gap-4 text-sm sm:gap-6 sm:p-6'>
          {/* Confirm submission */}
          <button
            onClick={() => {
              setIsFinalRender(true);
            }}
            className='z-90 rounded-md border border-fnac-mustard bg-fnac-mustard px-8 py-4 font-jakarta font-bold text-fnac-black'>
            {t('Fnac.SubmissionModal.Confirm')} &nbsp;
            <FontAwesomeIcon icon={faTrophy}></FontAwesomeIcon>
          </button>
          <div className='flex flex-row gap-4'>
            {/* Cancel submission */}
            <button
              onClick={() => {
                resetCanvas();
                onClose();
              }}
              className='z-90 rounded-md bg-transparent p-2 font-jakarta text-white sm:px-8 sm:py-4'>
              {t('Fnac.SubmissionModal.Cancel')}
            </button>

            {/* Reset image */}
            <button
              className='z-90 rounded-md border border-white bg-transparent p-2 font-jakarta text-white sm:px-8 sm:py-4'
              onClick={() => {
                resetCanvas();
              }}>
              {' '}
              Réinitialiser
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
