import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { GenerateImage } from "../../../../hooks/useGenerate";
import { DesignStyle } from "../../../../types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowPointer, faMagicWandSparkles } from "@fortawesome/free-solid-svg-icons";
import AttemptsCounter from "./AttemptsCounter";



export const ImagineFnac = function ImagineFnac({
    graphicStyle,
    setGraphicStyle,
    input,
    setInput,
    onGetSelection,
    generateImage,
    isEnabled,
    error,
    generationsLeft,
}: {
    graphicStyle: DesignStyle,
    setGraphicStyle: Dispatch<SetStateAction<DesignStyle>>
    input: string,
    setInput: Dispatch<SetStateAction<string>>,
    onGetSelection: () => string | null,
    generateImage: GenerateImage,
    isEnabled: boolean,
    error: string | null,
    generationsLeft: number,
}) {
    const { t } = useTranslation();

    const SUGGESTIONS: { [k: string]: string } = {
        [DesignStyle.Industrial]: t("DesignStyle.Industrial"),
        [DesignStyle.Line]: t("DesignStyle.Line"),
        [DesignStyle.Davinci]: t("DesignStyle.Davinci"),
        [DesignStyle.HandSketch]: t("DesignStyle.HandSketch"),
        [DesignStyle.FashionDrawing]: t("DesignStyle.FashionDrawing"),
        [DesignStyle.Scientific]: t("DesignStyle.Scientific"),
        [DesignStyle.Photorealistic]: t("DesignStyle.Photorealistic"),
    };

    const handleGenerate = async () => {
        const dataImg = await onGetSelection();
        if (dataImg && input && graphicStyle)
            await generateImage({ input, graphicStyle, dataImg });
    };

    const numberClasses = " bg-fnac-mustard h-[42px] w-[42px] min-w-[32px] flex items-center font-ccsign justify-center rounded-full text-fnac-black text-xl"

    let buttonContent =
        <>
            Donner vie à mon idée
            <FontAwesomeIcon icon={faMagicWandSparkles}></FontAwesomeIcon>
        </>

    if (!input) {
        buttonContent =
            <>
                Aucun texte renseigné
                <FontAwesomeIcon icon={faArrowPointer}></FontAwesomeIcon>
            </>
    }

    if (!isEnabled) {
        buttonContent =
            <>
                Aucun dessin sélectionné
                <FontAwesomeIcon icon={faArrowPointer}></FontAwesomeIcon>
            </>
    }
    if (generationsLeft <= 0) {
        buttonContent =
            <>
                {t("Beink.Error.noAttemptLeftButton")}

            </>
    }
    return (

        <div className="rounded-[32px] gap-8 prompt-form w-full h-full  bg-fnac-dark-gray font-si p-5 text-[14px] flex flex-col justify-between items-center"
        >
            <div className="flex flex-col gap-4 w-full overflow-auto">
                <div className="flex flex-col font-ccsign items-center bg-grey-400 p-8 rounded-2xl text-center gap-4" >

                    <div className="flex flex-col gap-2 text-center items-center">
                        <span className={numberClasses}> 1</span >

                        <span className="block text-base max-w-[200px]">Je dessine sur le tableau ce que m'évoque la Fnac</span>
                    </div>

                    <div className="flex flex-col items-center mw-200 gap-4 justify-center" >
                        <img src="v2/outil_dessin.png" alt="" className="w-2/3 block" />
                        <span className="block items-center font-thin font-jakarta max-w-[200px] text-center text-base">Mon visuel sera incrusté sur la carte Fnac+ !</span>
                    </div>

                </div >

                <div className="flex flex-col gap-8 bg-grey-400 p-6 rounded-2xl ">
                    <div className="flex flex-row font-ccsign justify-center items-center gap-2 ">
                        <span className={numberClasses} >2</span>
                        <span className="text-base text-center max-w-[200px]">Je décris ici mon dessin et choisis mon style visuel</span>
                        <img src={"v2/arrow.svg"} alt="" />
                    </div>
                    <textarea
                        id="prompt"
                        className="w-full pb-0 rounded-2xl !p-4"
                        rows={4}
                        placeholder={t("Beink.Generate.promptPlaceholder")}
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                    />
                    <select
                        id="graphic-style"
                        className="rounded-2xl p-4 h-12 text-base"
                        value={graphicStyle}
                        onChange={(e) => {
                            setGraphicStyle(e.target.value as DesignStyle);
                        }}
                    >
                        {Object.entries(SUGGESTIONS).map((key) => (
                            <option value={key[0]} key={key[0]}>
                                {key[1]}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="flex flex-col items-center gap-6 font-jakarta w-full  bg-grey-400 p-6 rounded-2xl">

                    <div className="flex sm:flex-col items-center gap-3  w-full">
                        <div className="flex flex-col gap-2 items-center w-full">
                            <span className={numberClasses}>3</span>
                            <span className=" font-ccsign text-center text-base max-w-[200px]">Je sélectionne mon dessin  et je génère l’image</span>
                        </div>
                        <img src="v2/outil_selection.png" className="w-1/2 hidden sm:block" alt="" />
                    </div>

                </div>
            </div>
            <div className="w-full flex flex-col gap-4 border-grey-400 border-t-[1px] pt-4">
                <AttemptsCounter className={"self-center"} attemptsCount={generationsLeft} />

                {/* GENERATE button */}
                <button
                    className={` self-end font-jakarta mt-2 flex items-center justify-center !p-4 !text-base !font-extrabold gap-2 w-full rounded-2xl ${isEnabled && input ? "!bg-fnac-mustard" : "bg-grey-400"}`}
                    onClick={handleGenerate}
                    disabled={!isEnabled || !input || generationsLeft <= 0}
                >
                    {buttonContent}
                </button>

                {(error) && (
                    <div
                        className="alert-danger"
                        role="alert"
                        dangerouslySetInnerHTML={{ __html: error || "" }}
                    ></div>
                )}
            </div>


        </div>

    )
}


