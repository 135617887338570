import { Dispatch, SetStateAction, useState } from 'react';
import useGenerate from '../../../../hooks/useGenerate';

import { BeinkPhase, DesignStyle } from '../../../../types';

import useAppContext from '../../../../hooks/useAppContext';
import {
  faMagicWandSparkles,
  faArrowPointer,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loading from './Loading';

import Accordion from './Accordion';

import GradientButton from '../../../lib/GradientButton';
import { useTranslation } from 'react-i18next';
import LoadingGradientButton from '../../../lib/LoadingGradientButton';
import { faHourglass } from '@fortawesome/free-regular-svg-icons';

type ImagineProps = {
  exportSelectionFromCanvas: () => string | null;
  isAnObjectSelected: boolean;
  setPhase: Dispatch<SetStateAction<BeinkPhase>>;
  setLockGeneration: Dispatch<SetStateAction<boolean>>;
  lockGeneration: boolean;
};

const Imagine = ({
  exportSelectionFromCanvas,
  isAnObjectSelected,
  setPhase,
  setLockGeneration,
  lockGeneration,
}: ImagineProps) => {
  const { generateImage, error, isLoading } = useGenerate();
  const { t } = useTranslation();

  const [input, setInput] = useState('');
  const [graphicStyle, setGraphicStyle] = useState<DesignStyle>(
    DesignStyle.Industrial
  );

  const { generationsLeft } = useAppContext();

  const SUGGESTIONS: { [k: string]: string } = {
    [DesignStyle.Industrial]: t('DesignStyle.Industrial'),
    [DesignStyle.Line]: t('DesignStyle.Line'),
    [DesignStyle.Davinci]: t('DesignStyle.Davinci'),
    [DesignStyle.HandSketch]: t('DesignStyle.HandSketch'),
    [DesignStyle.FashionDrawing]: t('DesignStyle.FashionDrawing'),
    [DesignStyle.Scientific]: t('DesignStyle.Scientific'),
    [DesignStyle.Photorealistic]: t('DesignStyle.Photorealistic'),
  };

  let buttonContent = (
    <>
      Donner vie à mon idée
      <FontAwesomeIcon icon={faMagicWandSparkles}></FontAwesomeIcon>
    </>
  );

  if (!input) {
    buttonContent = (
      <>
        Aucun texte renseigné
        <FontAwesomeIcon icon={faArrowPointer}></FontAwesomeIcon>
      </>
    );
  }

  if (!isAnObjectSelected) {
    buttonContent = (
      <>
        Aucun dessin sélectionné
        <FontAwesomeIcon icon={faArrowPointer}></FontAwesomeIcon>
      </>
    );
  }
  if (lockGeneration) {
    buttonContent = (
      <>
        Génération en cours
        <FontAwesomeIcon icon={faHourglass}></FontAwesomeIcon>
      </>
    );
  }

  if (generationsLeft <= 0) {
    buttonContent = <>{t('Beink.Error.noAttemptLeftButton')}</>;
  }

  const handleGenerate = async () => {
    const dataImg = await exportSelectionFromCanvas();
    if (dataImg && input && graphicStyle) {
      setLockGeneration(true);
      await generateImage({ input, graphicStyle, dataImg });
      if (error !== null) {
        setPhase(BeinkPhase.Results);
      }
      setLockGeneration(false);
    }
  };

  return (
    <Accordion
      header={
        <div className='flex items-center gap-2'>
          <FontAwesomeIcon icon={faMagicWandSparkles} className='text-lg' />
          <span className='text-md font-jakarta font-bold'>
            {t('Beink.Modes.imagine')}
          </span>
        </div>
      }
      initialState={true}>
      {isLoading ? (
        <div className='py-3'>
          <Loading />
        </div>
      ) : (
        <>
          <div className='flex w-full flex-col gap-4 overflow-auto pt-3'>
            <div className='flex flex-col gap-8 rounded-2xl bg-grey-400'>
              <textarea
                id='prompt'
                className='w-full rounded-2xl !p-4 pb-0'
                rows={4}
                placeholder={t('Beink.Generate.promptPlaceholder')}
                value={input}
                onChange={(e) => setInput(e.target.value)}
              />
              <select
                id='graphic-style'
                className='h-12 rounded-2xl p-4 text-base'
                value={graphicStyle}
                onChange={(e) => {
                  setGraphicStyle(e.target.value as DesignStyle);
                }}>
                {Object.entries(SUGGESTIONS).map((key) => (
                  <option value={key[0]} key={key[0]}>
                    {key[1]}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </>
      )}
      <div className='flex w-full flex-col gap-4 pt-4'>
        {error && (
          <div
            className='alert-danger'
            role='alert'
            dangerouslySetInnerHTML={{ __html: error || '' }}></div>
        )}

        {/* GENERATE button */}
        {isLoading ? (
          <LoadingGradientButton
            className={`${isAnObjectSelected && input ? 'beink-linear-gradient' : 'bg-grey-400'}`}
            onClick={handleGenerate}
            disabled={!isAnObjectSelected || !input || generationsLeft <= 0}>
            {t('Beink.Loading.title')}
          </LoadingGradientButton>
        ) : (
          <GradientButton
            onClick={handleGenerate}
            disabled={
              !isAnObjectSelected ||
              !input ||
              generationsLeft <= 0 ||
              lockGeneration
            }>
            {buttonContent}
          </GradientButton>
        )}
      </div>
    </Accordion>
  );
};

export default Imagine;
